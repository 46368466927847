import { makeAutoObservable } from "mobx";
import _ from "lodash";
import repository from "src/repositories/Indices";
import pythonDSLRepository from "src/repositories/PythonDSL";
import AnalysisRepository from "src/repositories/Analysis";
import {Dict, Rank, SelectType, StoreState} from "src/types/common";
import {HighLightOptions, Index, RankingRule, SearchRule} from "src/types/index/model";
import { PythonDSL } from "src/types/python-dsl/model";
import { PythonDSLCreate, PythonDSLUpdate } from "src/types/python-dsl/schema";
import {Analyzers} from "src/types/analysis/schema";

export default class ActiveIndex {
  storageId: string = "activeIndexId";
  index?: Index;
  pythonDSL?: PythonDSL;
  dsl_function: string = "";
  analyzers?: Analyzers;
  search_analyzers: SelectType[] = [];
  indexing_analyzers: SelectType[] = [];
  fieldBuckets?: Dict;
  state: StoreState = "none";
  dicSyncState: StoreState = "none";

  constructor() {
    makeAutoObservable(this);
  }

  async getId() {
    return localStorage.getItem(this.storageId);
  }

  async set(index?: Index, demo?: boolean) {
    this.state = "pending";
    if (index) {
      this.index = undefined;
      this.fieldBuckets = undefined;
      localStorage.setItem(this.storageId, index._id);
      await this.readPythonDSL(index.application_id, index.name);
      if (!demo && index.filter_fields!.length) {
        await this.readFieldBuckets(index.application_id, index);
      }
      if (!demo) {
        await this.readAnalyzersMany(index)
      }
      this.index = _.clone(index);
      this.state = "done";
    } else {
      localStorage.removeItem(this.storageId);
      this.index = undefined;
      this.state = "none";
    }
  }

  async justSet(index: Index) {
    if (index._id !== this.index!._id) return;
    this.index = index;
  }

  async readFieldBuckets(applicationId: string, index: Index) {
    try {
      const response = await repository.readFieldBuckets(
        applicationId,
        index.name,
        index.filter_fields!
      );
      this.fieldBuckets = response.data.buckets;
    } catch (e) {
      throw e;
    }
  }

  noFieldBuckets() {
    this.fieldBuckets = undefined;
  }

  async readAnalyzersMany(index: Index) {
    try {
      const response = await AnalysisRepository.readManyByIndex(index.application_id, index.name);
      this.analyzers = response.data;
      this.search_analyzers = Object.entries(this.analyzers!.search_analyzers).map(([k, v]) => {
        return {label: k ,value: v};
      });
      this.indexing_analyzers = Object.entries(this.analyzers!.indexing_analyzers).map(([k, v])=>{
        return {label: k, value: v};
      });
    } catch (e) {
      throw e;
    }
  }

  async analyze(
    applicationId: string,
    indexName: string,
    text: string,
    analyzers: string
  ) {
    try {
      const res = await AnalysisRepository.analyze(
        applicationId,
        indexName,
        text,
        analyzers
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async readPythonDSL(applicationId: string, indexName: string) {
    try {
      const response = await pythonDSLRepository.readMany(
        applicationId,
        indexName
      );
      this.pythonDSL = response.data.python_dsls
        ? response.data.python_dsls[0]
        : undefined;
      if (this.pythonDSL) this.dsl_function = this.pythonDSL?.dsl_function;
    } catch (e) {
      throw e;
    }
  }

  async setDSLFunction(dsl_function: string) {
    this.dsl_function = dsl_function;
  }

  async updateDSLFunction(
    applicationId: string,
    indexName: string,
    payload: PythonDSLUpdate
  ) {
    try {
      const response = await pythonDSLRepository.update(
        applicationId,
        indexName,
        this.pythonDSL!._id,
        payload
      );
      this.pythonDSL = response.data;
    } catch (e) {
      throw e;
    }
  }

  async createDSLFunction(
    applicationId: string,
    indexName: string,
    payload: PythonDSLCreate
  ) {
    try {
      const response = await pythonDSLRepository.create(
        applicationId,
        indexName,
        payload
      );
      this.pythonDSL = response.data;
    } catch (e) {
      throw e;
    }
  }

  async popAndPushSearchRule(targetIndex: number, destIndex: number) {
    const list = [...this.index!.search_rules!];
    const item = list.splice(targetIndex, 1);
    list.splice(destIndex, 0, item[0]);
    this.index!.search_rules = list;
  }

  async setSearchRule(index: number, rule: SearchRule) {
    this.index!.search_rules![index] = rule;
  }

  async setHighlightOptions(options: HighLightOptions) {
    this.index!.highlight_options = options;
  }

  async setRankingRule(rank: Rank, rule: RankingRule) {
    if (this.index!.ranking_rules!.length > rank-1)
      this.index!.ranking_rules![rank-1] = rule;
    else
      this.index!.ranking_rules!.push(rule);
  }

  async resetRankingRule() {
    this.index!.ranking_rules = [];
  }

  async deleteSearchRule(index: number) {
    const list = [...this.index!.search_rules!];
    list.splice(index, 1);
    this.index!.search_rules = list;
  }

  async deleteSynonymDomain(domain: string) {
    this.index!.synonym_domains = this.index!.synonym_domains.filter(
      (d) => d !== domain
    );
  }

  async pushSynonymDomain(domain: string) {
    this.index!.synonym_domains.push(domain);
  }

  async syncDictionary(target: string) {
    this.dicSyncState = "pending";
    const { application_id, name } = this.index!;

    try {
      const response = await repository.syncDictionary(application_id, name, target);
      
      this.dicSyncState = "done";
      return response.data;
    } catch (e: any) {
      this.dicSyncState = "error";
      throw e;
    } 
  }

  async clear() {
    this.state = "none";
    this.fieldBuckets = undefined;
    this.analyzers = undefined;
    this.indexing_analyzers = [];
    this.search_analyzers = [];
    this.dsl_function = "";
    this.pythonDSL = undefined;
    this.index = undefined;
  }
}
