import { makeAutoObservable } from "mobx";
import repository from "src/repositories/Trend";
import { StoreState } from "src/types/common";
import { Trend } from "src/types/trend/model";
import { TrendSearchType, TrendSearchTargetField } from "src/types/common";
import { TrendCreate, TrendSearch, TrendUpdate, TrendDelete } from "src/types/trend/schema";

class TrendList {
  state: StoreState = "none";
  trendList: Trend[] = [];
  trend?: Trend;
  searchQuery: string = "";
  searchType: TrendSearchType = { en: "all", ko: "전체" };
  searchTarget: TrendSearchTargetField = { en: "all", ko: "전체" };
  page: number = 1;
  size: number = 20;
  deleteIdList: string[] = [];
  totalCount: number = 0;
  totalPage: number = 1;
  sort: string[] = ["-created_dt"];

  constructor() {
    makeAutoObservable(this);
  }

  setTrend(trend?: Trend) {
    this.trend = trend;
  }

  setSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }

  setSearchType(searchType: TrendSearchType) {
    this.searchType = searchType;
  }

  setSearchTarget(searchTarget: TrendSearchTargetField) {
    this.searchTarget = searchTarget;
  }

  setPage(page: number) {
    this.page = page;
  }

  setDeleteIdList(list: string[]) {
    this.deleteIdList = list;
  }

  setSort(sort: string[]) {
    this.sort = sort;
  }

  async search(applicationId: string) {
    const payload: TrendSearch = {
      query: this.searchQuery,
      type: this.searchType.en,
      target: this.searchTarget.en,
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      const response = await repository.search(applicationId, payload);
      const tempList = response.data.trends;

      for (let i = 0; i < tempList.length; i++) {
        const newType = {
          en: tempList[i].type,
          ko: tempList[i].type === "keyword" ? "키워드" : "정규표현식",
        };

        tempList[i].type = newType;
      }

      this.trendList = tempList;
      this.totalCount = response.data.total;
      this.totalPage = response.data.total_page;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async create(applicationId: string, payload: TrendCreate) {
    await repository.create(applicationId, payload);
  }

  async upload(applicationId: string, formData: FormData) {
    await repository.upload(applicationId, formData);
  }

  async update(applicationId: string, bannedwordId: string, payload: TrendUpdate) {
    await repository.update(applicationId, bannedwordId, payload);
  }

  async delete(applicationId: string, payload: TrendDelete) {
    await repository.delete(applicationId, payload);
  }

  clear() {
    this.state = "none";
    this.trendList = [];
    this.trend = undefined;
    this.searchQuery = "";
    this.searchType = { en: "all", ko: "전체" };
    this.searchTarget = { en: "all", ko: "전체" };
    this.page = 1;
    this.deleteIdList = [];
    this.totalCount = 0;
    this.totalPage = 1;
    this.sort = ["-created_dt"];
  }

  setReady() {
    this.state = "none";
  }
}

export default TrendList;
