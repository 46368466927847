import { makeAutoObservable } from "mobx";
import { Dict, StoreState } from "src/types/common";
import moment from "moment";
import repository from "src/repositories/AuditLogList";

const NOW = new Date();
const PAGE_SIZE = 20;

export interface AuditLogType {
  user_email: string;
  index_name: string;
  resource: string;
  operation: string;
  status: string;
  detail: Dict;
  updated_dt: string;
}

export interface AuditLogFilter {
  [key: string]: any;
  status?: "success" | "fail" | "pending";
  operation?: "Create" | "Update" | "Delete";
  resource?: string;
  index_name?: string;
  user_email?: string;
}

export type KeyType =
  | "status"
  | "operation"
  | "resource"
  | "index_name"
  | "user_email"
  | "date";

export interface Filter {
  key: KeyType;
  value?: string;
  gte?: Date;
  lte?: Date;
}

export default class AuditLogList {
  state: StoreState = "none";
  sort: string[] = ["-updated_dt"];
  filters: AuditLogFilter = {};
  gte: Date = new Date(new Date().setDate(NOW.getDate() - 365));
  lte: Date = NOW;
  page: number = 1;
  size: number = PAGE_SIZE;
  isMinimized: boolean = false;
  selectedDetail: string = "";

  auditLogList: AuditLogType[] = [];
  resources: string[] = [];
  lastPage: number = 1;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(applicationId: string) {
    this.state = "pending";

    const payload = {
      sort: this.sort,
      filters: this.filters,
      date: {
        gte: moment(this.gte).toISOString(true),
        lte: moment(this.lte).toISOString(true),
      },
      page: this.page,
      size: this.size,
    };

    try {
      const response = await repository.readMany(applicationId, payload);

      this.auditLogList = response.data.audit_logs;
      this.lastPage = response.data.total_page || 1;
      this.state = "done";
    } catch (err) {
      this.state = "error";
      console.error(err);
    }
  }

  async getResources(applicationId: string) {
    try {
      const response = await repository.getResources(applicationId);
      this.resources = response.data.resources;
    } catch (err) {
      console.log(err);
    }
  }

  get resourceList() {
    return this.resources.map((item) => {
      return { value: item, label: item };
    });
  }

  addFilter(data: Filter) {
    this.filters = {
      ...this.filters,
      [data.key]: data.value,
    };
  }

  deleteFilter(key: KeyType) {
    if (this.filters) delete this.filters[key];
  }

  changeSort() {
    if (this.sort[0].startsWith("-")) this.sort = ["+updated_dt"];
    else this.sort = ["-updated_dt"];

    this.setReady();
  }

  setReady() {
    this.state = "none";
  }

  setPage(page: number) {
    this.page = page;
  }

  setGte(gte: Date) {
    this.gte = gte;
  }

  setLte(lte: Date) {
    this.lte = lte;
  }

  resetGte() {
    this.setGte(new Date(new Date().setDate(NOW.getDate() - 365)));
  }

  resetLte() {
    this.setLte(NOW);
  }

  resetDate() {
    this.setGte(new Date(new Date().setDate(NOW.getDate() - 365)));
    this.setLte(NOW);
  }

  setIsMinimized(value: boolean) {
    this.isMinimized = value;
  }

  setSelectedDetail(detail: string) {
    this.selectedDetail = JSON.stringify(JSON.parse(detail), null, 4);
  }

  clear() {
    this.sort = ["-updated_dt"];
    this.filters = {};
    this.resetDate();
    this.page = 1;
    this.state = "none";
  }
}
