import Base from "src/repositories";
import { MemberCreate, MemberUpdate } from "src/types/member/schema";

class Member extends Base {
  constructor() {
    super({ prefix: "/members" });
  }

  async create(payload: MemberCreate) {
    return this.axiosInstance.post(``, payload);
  }

  async delete(memberId: string) {
    return this.axiosInstance.delete(`/${memberId}`);
  }

  async update(memberId: string, payload: MemberUpdate) {
    return this.axiosInstance.put(`/${memberId}`, {
      role: payload.role,
    });
  }
}

export default new Member();
