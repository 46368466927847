import moment from "moment";
import { LineGraphType } from "src/types/common";
import { BaseRequest } from "src/types/statistics/schema";

// Date
export const utcToLocalSecond = (date: string) => {
  return moment(date).local().format("YYYY-MM-DD HH:mm:ss");
};

export const utcToLocal = (date: string, format?: string) => {
  return moment
    .utc(date)
    .local()
    .format(format || "yyyy.MM.DD");
};

export const localToUtc = (date: string) => {
  return moment(date).utc().format();
};

// String
export const makeEllipsis = (string: string, limit?: number) => {
  if (!limit) return string;
  const text = String(string);
  if (text.length <= limit) {
    return text;
  } else {
    return text.substring(0, limit).concat("...");
  }
};

// Graph
export const initializeLte = () => {
  return new Date();
};

export const initializeGte = () => {
  const now = new Date();
  return new Date(now.setDate(now.getDate() - 30));
};

export const makeDateString = (date: Date | null) => {
  if (date) return moment(date).format().substring(0, 10);
  else return "";
};

export const makeDateTimeString = (date: Date | null) => {
  if (date) return moment(date).format().substring(0, 19);
  else return "";
};

export const makeBaseRequestPayload = (gte: Date, lte: Date) => {
  const diff = moment(lte).diff(gte);
  let payload: BaseRequest | null = null;

  if (diff === 0) {
    payload = {
      gte: makeDateTimeString(gte!),
      lte: makeDateTimeString(moment(lte).add(1, "day").toDate()),
    };
  } else {
    payload = {
      gte: makeDateString(gte!),
      lte: makeDateString(lte!),
    };
  }

  return payload;
};

export const generateCrowscareArray = (endDate: Date, duration: number) => {
  const array = [];
  const date = new Date(endDate);

  for (let i = 0; i < duration; i++) {
    const newDate = new Date(endDate);
    newDate.setDate(date.getDate() - i);

    array.push({
      x: newDate,
      y: 0,
    });
  }

  return array;
};

interface MakeLineGraphDataInput {
  indexNames: string[];
  dates: string[];
  data: object[];
  endDate: Date;
  diff: number;
}

export const makeLineGraphData = ({
  indexNames,
  dates,
  data,
  endDate,
  diff,
}: MakeLineGraphDataInput): LineGraphType[] => {
  const arr: LineGraphType[] = [];

  if (indexNames.length === 0) {
    arr.push({
      id: "no-index",
      data: generateCrowscareArray(endDate, diff),
    });
  } else {
    for (const indexName of indexNames) {
      arr.push({
        id: indexName,
        data: [],
      });
    }

    for (let i = 0; i < data.length; i++) {
      const oneData = Object.values(data[i]);

      for (let j = 0; j < oneData.length; j++) {
        arr[j].data.push({
          x: moment(dates[i]).toDate(),
          y: oneData[j],
        });
      }
    }
  }

  return arr;
};

export const makeSimpleLineGraphData = ({
  indexNames,
  dates,
  data,
  endDate,
  diff,
}: MakeLineGraphDataInput): LineGraphType[] => {
  const arr: LineGraphType[] = [];

  if (indexNames.length === 0) {
    arr.push({
      id: "no-index",
      data: generateCrowscareArray(endDate, diff),
    });
  } else {
    for (const indexName of indexNames) {
      arr.push({
        id: indexName,
        data: [],
      });
    }

    for (let i = 0; i < data.length; i++) {
      const oneData = data[i];

      arr[0].data.push({
        x: moment(dates[i]).toDate(),
        y: Number(oneData),
      });
    }
  }

  return arr;
};

// TODO: It's deprecated. It need to be fixed.
export const copyText = (target: string) => {
  if (!document.queryCommandSupported("copy")) {
    return console.error("복사하기를 지원하지 않는 브라우저입니다.");
  }

  const textarea = document.createElement("textarea");
  textarea.value = target;
  textarea.style.position = "fixed";
  textarea.style.left = "-999999px";
  textarea.style.top = "-999999px";

  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
};

export const unsecuredCopyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }

  document.body.removeChild(textArea);
};

export const getDifferenceDays = (date1: Date, date2: Date) => {
  const DAY = 1000 * 60 * 60 * 24;

  return Math.floor(
    (Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) -
      Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())) /
      DAY
  );
};
