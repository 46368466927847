import Base from "src/repositories";
import {
  AutoCPLDictCreate,
  AutoCPLDictUpdate,
  AutoCPLDictDelete,
  AutoCPLDictFind,
  AutoCPLSearch,
  BatchActionResponse,
  AutoCPLDictListResponse,
  AutoCPLSearchResponse
} from "src/types/auto-complete-dict/schema";

import { AutoCPLDict } from "src/types/auto-complete-dict/model";


class AutoCplDict extends Base {
  constructor() {
    super({});
  }

  async create(applicationId: string, payload: AutoCPLDictCreate) {
    return this.axiosInstance.post<AutoCPLDict>(`/${applicationId}/autocpl`, payload);
  }

  async update(applicationId: string, autocplId: string, payload: AutoCPLDictUpdate) {
    return this.axiosInstance.put<AutoCPLDict>(
        `/${applicationId}/autocpl/${autocplId}`, payload);
  }

  async delete_many(applicationId: string, payload: AutoCPLDictDelete) {
    return this.axiosInstance.delete<BatchActionResponse>(`/${applicationId}/autocpl`, {data: payload});
  }

  async read_many(applicationId: string, params: AutoCPLDictFind) {
    return this.axiosInstance.get<AutoCPLDictListResponse>(`/${applicationId}/autocpl`, {params: params});
  }

  async search(applicationId: string, params: AutoCPLSearch) {
    return this.axiosInstance.get<AutoCPLSearchResponse>(`/${applicationId}/autocpl/ac-search`, {params: params});
  }

  async upload(applicationId: string, payload: FormData)  {
    return this.axiosInstance.post<BatchActionResponse>(`/${applicationId}/autocpl/upload`, payload);
  }
}

export default new AutoCplDict();
