import { makeAutoObservable } from "mobx";
import repository from "src/repositories/Token";
import { StoreState } from "src/types/common";
import { Token } from "src/types/token/model";
import { TokenCreate, TokenUpdate } from "src/types/token/schema";
import _ from "lodash";

export default class TokenList {
  state: StoreState = "none";
  tokens: Token[] = [];
  token?: Token;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(applicationId: string) {
    this.state = "pending";
    try {
      const response = await repository.readMany(applicationId);
      this.tokens = response.data.tokens ? response.data.tokens : [];
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async set(token?: Token) {
    if (token) {
      this.token = _.clone(token);
    } else {
      this.token = undefined;
    }
  }

  async create(payload: TokenCreate) {
    try {
      await repository.create(payload);
    } catch (e) {
      throw e;
    }
  }

  async update(tokenId: string, payload: TokenUpdate) {
    try {
      await repository.update(tokenId, payload);
    } catch (e) {
      throw e;
    }
  }

  async delete(tokenId: string) {
    try {
      await repository.delete(tokenId);
    } catch (e) {
      throw e;
    }
  }

  async clearToken() {
    this.token = undefined;
  }

  async clear() {
    this.state = "none";
    this.tokens = [];
    this.token = undefined;
  }
}
