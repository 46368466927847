import { makeAutoObservable } from "mobx";
import repository from "src/repositories/PopularTerm";
import { StoreState } from "src/types/common";
import { PopularTerm } from "src/types/popular-term/model";
import { GetPopularTermList, UpdatePopularTermList } from "src/types/popular-term/schema";
import { utcToLocal } from "src/utils";

const NOW = new Date();
const GTE = utcToLocal(new Date(new Date().setDate(NOW.getDate() - 30)).toISOString());
const LTE = utcToLocal(NOW.toISOString());

class PopularTermList {
  state: StoreState = "none";
  list: PopularTerm[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getList(applicationId: string) {
    this.state = "pending";
    const payload: GetPopularTermList = {
      interval: 60,
      size: 20,
      range_: {
        gte: GTE,
        lte: LTE,
      },
    };

    try {
      const response = await repository.getList(applicationId, payload);
      this.list = response.data.popular_terms;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      console.error(e);
    }
  }

  async updateList(applicationId: string) {
    this.state = "pending";

    const payload: UpdatePopularTermList = {
      range_: {
        gte: GTE,
        lte: LTE,
      },
    };
    try {
      await repository.updateList(applicationId, payload);
      this.state = "done";
    } catch (e) {
      this.state = "error";
    }
  }

  clear() {
    this.state = "none";
    this.list = [];
  }

  setReady() {
    this.state = "none";
  }
}

export default PopularTermList;
