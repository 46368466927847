import {makeAutoObservable} from "mobx";
import repository from "src/repositories/User";
import adminRepository from "src/repositories/Admin";
import {LoginUser, UpdateUser, UserInfo} from "src/types/user/schema";
import {StoreState} from "src/types/common";
import { UserCreate, UserChangePassword } from "src/types/user/schema";

export default class UserList {
  state: StoreState = "none";
  users?: UserInfo[]
  user?: UserInfo | LoginUser

  constructor() {
    makeAutoObservable(this);
  }

  async set(user: UserInfo | LoginUser) {
    this.user = user;
  }

  async update(email: string, payload: UpdateUser, isAdmin: boolean) {
    try {
      const res = isAdmin
        ? await adminRepository.updateUser(email, payload)
        : await repository.update(email, payload);
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async readMany() {
    this.state = "pending";
    try {
      const res = await adminRepository.readUserMany();
      this.users = res.data.users;
      this.state = "done";
    } catch (e) {
      throw e;
    }
  }

  async justReadMany() {
    try {
      const res = await adminRepository.readUserMany();
      this.users = res.data.users;
    } catch (e) {
      throw e;
    }
  }

  async read(email: string) {
    try {
      const res = await repository.read(email);
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async join(payload: UserCreate) {
    try {
      const res = await repository.join(payload);
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async changePassword(payload: UserChangePassword) {
    try {
      await repository.changePassword(payload);
    } catch (e) {
      throw e;
    }
  }

  async delete(email: string, isAdmin: boolean) {
    try {
      if (isAdmin)
        await adminRepository.deleteUser(email);
      else
        await repository.delete(email);
    } catch (e) {
      throw e;
    }
  }

  async clearUser() {
    this.user = undefined;
  }

  async clear() {
    this.state = "none";
    this.users = undefined;
    this.user = undefined;
  }
}
