/* Icons for tabs */
import DashBoardIconNor from "src/assets/images/icon-side-dashboard-nor.png";
import DashBoardIconSel from "src/assets/images/icon-side-dashboard-sel.png";
import IndexIconNor from "src/assets/images/icon-side-index-nor.png";
import IndexIconSel from "src/assets/images/icon-side-index-sel.png";
import DictIconNor from "src/assets/images/icon-side-dic-nor.png";
import DictIconSel from "src/assets/images/icon-side-dic-sel.png";
import ServiceIconNor from "src/assets/images/icon-side-service-nor.png";
import ServiceIconSel from "src/assets/images/icon-side-service-sel.png";
import StatIconNor from "src/assets/images/icon-side-research-nor.png";
import StatIconSel from "src/assets/images/icon-side-research-sel.png";
import MyAppIcon from "src/assets/icons/icon-side-myapp-nor.png";
import MyAppSelIcon from "src/assets/icons/icon-side-myapp-sel.png";
import ShareAppIcon from "src/assets/icons/icon-side-share-app-nor.png";
import ShareAppSelIcon from "src/assets/icons/icon-side-share-app-sel.png";

import { SelectType, Tab, TabWithSub, DisplayItem } from "src/types/common";
import { FieldCap, Schema } from "src/types/index/schema";

// Common
export const ADMIN_TAB_LIST = [
  {
    text: "Applications",
    eventKey: "applications",
    prefix: "/admin/applications",
    redirect: "/admin/applications",
    icon: ShareAppIcon,
    selIcon: ShareAppSelIcon,
  },
  {
    text: "Users",
    eventKey: "users",
    prefix: "/admin/users",
    redirect: "/admin/users",
    icon: MyAppIcon,
    selIcon: MyAppSelIcon,
  },
  {
    text: "Policy",
    eventKey: "policy",
    prefix: "/admin/policy",
    redirect: "/admin/policy/user",
    icon: DictIconNor,
    selIcon: DictIconSel,
  },
];

export const APPLICATION_TAB_LIST = [
  {
    text: "내 애플리케이션",
    eventKey: "my",
    prefix: "/applications/my",
    redirect: "/applications/my",
    icon: MyAppIcon,
    selIcon: MyAppSelIcon,
  },
  {
    text: "공유받은 애플리케이션",
    eventKey: "sharing",
    prefix: "/applications/sharing",
    redirect: "/applications/sharing",
    icon: ShareAppIcon,
    selIcon: ShareAppSelIcon,
  },
];

export const INDEX_TAP_LIST: Tab[] = [
  {
    text: "데이터조회",
    eventKey: "browse",
    prefix: "/indices/browse",
    redirect: "/indices/browse",
  },
  {
    text: "스키마",
    eventKey: "schema",
    prefix: "/indices/schema",
    redirect: "/indices/schema",
  },
  {
    text: "검색설정",
    eventKey: "search-settings",
    prefix: "/indices/search-settings",
    redirect: "/indices/search-settings",
  },
  {
    text: "설정",
    eventKey: "settings",
    prefix: "/indices/settings",
    redirect: "/indices/settings",
  },
];

export const DICTIONARY_TAB_LIST: Tab[] = [
  {
    text: "금칙어",
    eventKey: "stopword",
    prefix: "/dictionary/banned-word",
    redirect: "/dictionary/banned-word",
  },
  {
    text: "유의어",
    eventKey: "synonym",
    prefix: "/dictionary/synonym",
    redirect: "/dictionary/synonym",
  },
  {
    text: "사용자 사전",
    eventKey: "user-dictionary",
    prefix: "/dictionary/user-dictionary",
    redirect: "/dictionary/user-dictionary",
  },
  {
    text: "자동완성",
    eventKey: "autocomplete",
    prefix: "/dictionary/autocomplete",
    redirect: "/dictionary/autocomplete",
  },
  {
    text: "인기 검색어",
    eventKey: "trend",
    prefix: "/dictionary/trend",
    redirect: "/dictionary/trend",
  },
];

export const STATISTICS_TAB_LIST: Tab[] = [
  {
    text: "검색 로그",
    eventKey: "search-log",
    prefix: "/logs/search-log",
    redirect: "/logs/search-log",
  },
  {
    text: "Audit 로그",
    eventKey: "audit-log",
    prefix: "/logs/audit-log",
    redirect: "/logs/audit-log",
  },
];

export const MAIN_TAP_LIST: TabWithSub[] = [
  {
    text: "대시보드",
    eventKey: "dashboard",
    prefix: "/dashboard",
    redirect: "/dashboard/nodes",
    sub: undefined,
    iconNor: DashBoardIconNor,
    iconSel: DashBoardIconSel,
  },
  {
    text: "인덱스",
    eventKey: "indices",
    prefix: "/indices",
    redirect: "/indices/browse",
    sub: INDEX_TAP_LIST,
    iconNor: IndexIconNor,
    iconSel: IndexIconSel,
  },
  {
    text: "사전",
    eventKey: "dictionary",
    prefix: "/dictionary",
    redirect: "/dictionary/banned-word",
    sub: DICTIONARY_TAB_LIST,
    iconNor: DictIconNor,
    iconSel: DictIconSel,
  },
  {
    text: "서비스설정",
    eventKey: "demo-settings",
    prefix: "/demo-settings",
    redirect: "/demo-settings",
    sub: undefined,
    iconNor: ServiceIconNor,
    iconSel: ServiceIconSel,
  },
  {
    text: "통계",
    eventKey: "logs",
    prefix: "/logs",
    redirect: "/logs/search-log",
    sub: STATISTICS_TAB_LIST,
    iconNor: StatIconNor,
    iconSel: StatIconSel,
  },
];

export const PER_PAGE_OPTIONS: SelectType[] = [
  { value: 10, label: "10개씩 보기" },
  { value: 20, label: "20개씩 보기" },
  { value: 30, label: "30개씩 보기" },
];

// Application

export const ROLE: SelectType[] = [
  { label: "총괄 관리자", value: "general-manager" },
  { label: "검색 기획자", value: "search-manager" },
  { label: "데이터 편집자", value: "data-manager" },
  { label: "사전 편집자", value: "dictionary-manager" },
  { label: "조회", value: "guest" },
];

// Index

export const FIELD_TYPES: SelectType[] = [
  { value: "text", label: "Text" },
  { value: "keyword", label: "Keyword" },
  { value: "long", label: "Long" },
  { value: "float", label: "Float" },
  { value: "boolean", label: "Boolean" },
  { value: "date", label: "Date" },
  { value: "object", label: "Object" },
  { value: "nested", label: "Nested" },
];

export const OPERATOR: SelectType[] = [
  { value: "and", label: "and" },
  { value: "or", label: "or" },
];

export const ORDER: SelectType[] = [
  { value: "asc", label: "오름차순" },
  { value: "desc", label: "내림차순" },
];

export const SLOP_SEARCH_TYPES = ["phrase", "phrase_prefix"];

export const EDIT_DISTANCE_SEARCH_TYPES = [
  "bool_prefix",
  "most_fields",
  "best_fields",
];

export const SORT = (fields: FieldCap[]) => {
  const sortTypes = [
    { eng: "asc", kor: "오름차순" },
    { eng: "desc", kor: "내림차순" },
  ];
  const sort: SelectType[] = [];
  fields.forEach((field) => {
    sortTypes.forEach((sortType) => {
      sort.push({
        value: { field: field.origin, order: sortType.eng },
        label: `${field.name}(${sortType.kor})`,
      });
    });
  });
  return sort;
};

export const DISPLAY_ITEMS = (displayFields: string[], fields: FieldCap[]) => {
  const items: DisplayItem[] = [];
  const notDisplayField = fields
    .map((f) => f.name)
    .filter((f) => !displayFields.includes(f!));

  displayFields.forEach((displayField) => {
    const field = fields.filter((f) => f.name === displayField)[0];
    if (!field) return;
    items.push({ value: field.origin!, label: field.name!, display: true });
  });

  notDisplayField.forEach((displayField) => {
    const field = fields.filter((f) => f.name === displayField)[0];
    items.push({ value: field.origin!, label: field.name!, display: false });
  });

  return items;
};

export const FIELDS = (schemas: Schema[]) => {
  return schemas
    .filter((s) => s.properties!.type !== "object" && !s.parent)
    .map((s) => {
      return { value: s.field, label: s.field };
    });
};

export const SAMPLE_DSL = `def search_function(object: dict):
  query = object.get('query')
  dsl = {
    "query": {
      "match": {
        "[field]": query
      }
    }
  }
  return dsl
`;

export const SAMPLE_API_JSON = {
  records: [
    {
      field: "data",
    },
  ],
};

export const SAMPLE_API = (index_id: string, application_id: string) =>
  `curl --location --request POST '${
    process.env.REACT_APP_HOST
  }/${application_id}/${index_id}/documents' \\
--header 'Content-Type: application/json' \\
--header 'Search42-Application-ID: ${application_id}' \\
--header 'Cache-Control: no-cache' \\
--header 'Pragma: no-cache' \\
--header 'Expires: 0' \\
--header 'Access-Control-Allow-Origin: *' \\
--data-raw '${JSON.stringify(SAMPLE_API_JSON, undefined, 4)}'`;

export const URL_REGEX =
  // eslint-disable-next-line
  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

// bannedWord
export const BANNED_WORD_TYPE_OPTIONS: SelectType[] = [
  { value: "all", label: "전체" },
  { value: "adult", label: "성인어" },
  { value: "taboo", label: "금기어" },
];

export const BANNED_WORD_TARGET_OPTIONS: SelectType[] = [
  { value: "all", label: "전체" },
  { value: "keyword", label: "키워드" },
  { value: "creator", label: "작업자" },
];

export const BANNED_WORD_CATEGORY_TAB_LIST = ["성인어", "금기어"];
export const TREND_CATEGORY_TAB_LIST = ["키워드", "정규표현식"];
export const PROCESSING_METHOD_TAB_LIST = [
  "포함하는 경우",
  "일치하는 경우만",
  "포함시 금칙어 제외",
];

// Synonym
export const SYNONYM_TARGET_OPTIONS: SelectType[] = [
  { value: "all", label: "전체" },
  { value: "synonyms", label: "키워드+유의어" },
  { value: "creator", label: "작업자" },
];

// Userdict
export const USERDICT_TYPE_OPTIONS = [
  { value: "all", label: "전체" },
  { value: "noun", label: "일반명사" },
  { value: "proper", label: "고유명사" },
  { value: "compound", label: "복합명사" },
  { value: "etc", label: "기타" },
];
export const USERDICT_TARGET_OPTIONS = [
  { value: "all", label: "전체" },
  { value: "keyword", label: "키워드" },
  { value: "creator", label: "작업자" },
];
export const CLASS_TAB_LIST = ["일반명사", "고유명사", "복합명사", "기타"];

// Autocomplete
export const AUTOCOMPLETE_STATUS_OPTIONS: SelectType[] = [
  { value: "all", label: "전체" },
  { value: "enable", label: "활성화" },
  { value: "disable", label: "비활성화" },
];

// Trend
export const TREND_TYPE_OPTIONS: SelectType[] = [
  { value: "all", label: "전체" },
  { value: "keyword", label: "키워드" },
  { value: "regex", label: "정규표현식" },
];

export const TREND_TARGET_OPTIONS: SelectType[] = [
  { value: "all", label: "전체" },
  { value: "trends", label: "키워드" },
  { value: "creator", label: "작업자" },
];
