import axios, { AxiosInstance } from "axios";

const apiHost = process.env.REACT_APP_HOST!;
const apiPrefix = process.env.REACT_APP_PREFIX!;

export default class Base {
  axiosInstance: AxiosInstance;
  HOST: string;

  constructor({ host, prefix }: { host?: string; prefix?: string }) {
    this.HOST = `${apiHost.length ? apiHost : window.location.origin}${
      apiPrefix ?? apiPrefix
    }`;
    if (prefix) this.HOST += prefix;

    this.axiosInstance = axios.create({
      baseURL: this.HOST,
    });

    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const applicationId = window.localStorage.getItem("activeAppId");
        config.headers = {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          "Access-Control-Allow-Origin": apiHost,
        };
        if (applicationId)
          config.headers["Search42-Application-ID"] = applicationId;
        config.withCredentials = true;
        return config;
      },
      (error) => {
        // if (error.response.status === 404) {
        //   this.axiosInstance.get(`/auth/login`)
        //       .then((response)=>{
        //         window.location.href = response.headers.location;
        //       });
        //   console.log(error)
        //}
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      async (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // ErrorHandlingStore.setError('401')
        }
        if (error.response.status === 404) {
          // this.axiosInstance.get(`/auth/login`)
          //     .then((response)=>{
          //       window.location.href = response.headers.location;
          //     });
          // console.log(error)
        }
        return Promise.reject(error);
      }
    );
  }
}
