import { makeAutoObservable } from "mobx";
import repository from "src/repositories/AutoCplDict";
import { StoreState } from "src/types/common";

import {
  AutoCPLSearch,
  Hit,
  AutoCPLSearchResponse,
} from "src/types/auto-complete-dict/schema";

class AutoCplSearch {
  state: StoreState = "none";
  hits: Hit[] = [];
  searchQuery: string = "";
  page: number = 1;
  size: number = 20;
  totalCount: number = 0;
  totalPage: number = 1;
  lang: string = "kor";
  maxEditDistance: string = "";
  minTermLengthFor1dist: string = "";
  minTermLengthFor2dist: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }

  goPrevPage() {
    if (this.page === 1) return;
    else this.page--;

    this.setReady();
  }

  goNextPage() {
    if (this.page === this.totalPage) return;
    else this.page++;

    this.setReady();
  }

  setPage(page: number) {
    this.page = page;
  }

  setLanguage(lang: string) {
    this.lang = lang;
  }

  setMaxEditDistance(maxEditDistance: string) {
    this.maxEditDistance = maxEditDistance;
  }

  setMinTermLengthFor1dist(minTermLengthFor1dist: string) {
    this.minTermLengthFor1dist = minTermLengthFor1dist;
  }

  setMinTermLengthFor2dist(minTermLengthFor2dist: string) {
    this.minTermLengthFor2dist = minTermLengthFor2dist;
  }

  async search(applicationId: string) {
    const search_params: AutoCPLSearch = {
      query: this.searchQuery,
      page: this.page,
      size: this.size,
      max_edit_distance:
        this.maxEditDistance.length === 0
          ? undefined
          : Number(this.maxEditDistance),
      min_term_length_for_1dist:
        this.minTermLengthFor1dist.length === 0
          ? undefined
          : Number(this.minTermLengthFor1dist),
      min_term_length_for_2dist:
        this.minTermLengthFor2dist.length === 0
          ? undefined
          : Number(this.minTermLengthFor2dist),
      lang: this.lang,
    };
    try {
      const response = await repository.search(applicationId, search_params);
      const data: AutoCPLSearchResponse = response.data;

      this.totalCount = data.total;
      this.hits = data.hits;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  setReady() {
    this.state = "none";
  }

  clear() {
    this.state = "none";
    this.hits = [];
    this.searchQuery = "";
    this.page = 1;
    this.size = 20;
    this.totalCount = 0;
    this.totalPage = 1;
    this.lang = "kor";
    this.maxEditDistance = "";
    this.minTermLengthFor1dist = "";
    this.minTermLengthFor2dist = "";
  }
}

export default AutoCplSearch;
