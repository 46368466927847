import Base from "src/repositories";

class LogList extends Base {
  constructor() {
    super({});
  }

  async getLogs(applicationId: string, payload: any) {
    return this.axiosInstance.post(`/${applicationId}/search-log`, payload);
  }

  async getSearchTerms(applicationId: string, payload: any) {
    return this.axiosInstance.post(
      `/${applicationId}/search-log/log-stats`,
      payload
    );
  }
}

export default new LogList();
