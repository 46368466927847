import Base from "src/repositories";
import {
  BannedWordCreate,
  BannedWordUpdate,
  BannedWordDelete,
  BannedWordSearch,
} from "src/types/bannedword/schema";

class BannedWord extends Base {
  constructor() {
    super({});
  }

  async create(application_id: string, payload: BannedWordCreate) {
    return this.axiosInstance.post(`/${application_id}/bannedwords`, payload);
  }

  async search(application_id: string, payload: BannedWordSearch) {
    return this.axiosInstance.post(
      `/${application_id}/bannedwords/find`,
      payload
    );
  }

  async update(
    applicationId: string,
    bannedWordId: string,
    payload: BannedWordUpdate
  ) {
    return this.axiosInstance.put(
      `/${applicationId}/bannedwords/${bannedWordId}`,
      payload
    );
  }

  async delete(applicationId: string, payload: BannedWordDelete) {
    return this.axiosInstance.delete(`/${applicationId}/bannedwords`, {
      data: payload,
    });
  }

  async upload(applicationId: string, payload: FormData) {
    return this.axiosInstance.post(
      `${applicationId}/bannedwords/upload`,
      payload
    );
  }
}

export default new BannedWord();
