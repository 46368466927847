import Base from "src/repositories";
import { UserLogin } from "src/types/user/schema";

class Authentication extends Base {
  constructor() {
    super({ prefix: "/auth" });
  }

  async proofUser() {
    return this.axiosInstance.post(`/user/proof`);
  }

  async login(payload: UserLogin) {
    return this.axiosInstance.post(`/login`, payload);
  }

  async logout() {
    return this.axiosInstance.post(`/logout`)
  }

  async loginKeeper() {
    return this.axiosInstance.post(`/keeper/login`).then((response) => {
      window.location.href = response.headers.location;
    });
  }
}

export default new Authentication();
