import { makeObservable } from "mobx";
import repository from "src/repositories/Analysis";
import {Analyzers} from "src/types/analysis/schema";
import {SelectType, StoreState} from "src/types/common";

export default class Analysis {
  analyzers?: Analyzers;
  search_analyzers?: SelectType[];
  indexing_analyzers?: SelectType[];
  state: StoreState = "none";

  constructor() {
    makeObservable(this, {});
  }

  async readMany() {
    this.state = "pending";

    try {
      const response = await repository.readMany();
      this.analyzers = response.data;
      this.search_analyzers = Object.entries(this.analyzers!.search_analyzers).map(([k, v]) => {
        return {label: k ,value: v};
      });
      this.indexing_analyzers = Object.entries(this.analyzers!.indexing_analyzers).map(([k, v])=>{
        return {label: k, value: v};
      });
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async analyze(
    applicationId: string,
    indexName: string,
    text: string,
    analyzers: string
  ) {
    try {
      const res = await repository.analyze(
        applicationId,
        indexName,
        text,
        analyzers
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async tokenize(text: string, tokenizers: string) {
    try {
      const res = await repository.tokenize(text, tokenizers);
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async clear() {
    this.state = "none";
  }
}
