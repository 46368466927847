import DefaultRepository from "src/repositories";
import {
  IndexCreate,
  IndexDisplayUpdate,
  IndexCopy,
  IndexSearchSettingUpdate,
  IndexMappingUpdateMany,
} from "src/types/index/schema";

class Indices extends DefaultRepository {
  constructor() {
    super({});
  }

  async readMany(applicationId: string) {
    return this.axiosInstance.get(`/${applicationId}/indices`);
  }

  async create(applicationId: string, payload: IndexCreate) {
    return this.axiosInstance.post(`/${applicationId}/indices`, payload);
  }

  async copy(applicationId: string, indexName: string, payload: IndexCopy) {
    return this.axiosInstance.post(
      `/${applicationId}/indices/${indexName}/copy`,
      payload
    );
  }

  async read(applicationId: string, indexName: string, return_fields?: string) {
    return this.axiosInstance.get(`/${applicationId}/indices/${indexName}`, {
      params: { return_fields },
    });
  }

  async updateIndexDisplay(
    applicationId: string,
    indexName: string,
    payload: IndexDisplayUpdate
  ) {
    return this.axiosInstance.put(
      `/${applicationId}/indices/${indexName}/display`,
      payload
    );
  }

  async updateIndexSearchSettings(
    applicationId: string,
    indexName: string,
    payload: IndexSearchSettingUpdate
  ) {
    return this.axiosInstance.put(
      `/${applicationId}/indices/${indexName}/search-settings`,
      payload
    );
  }

  async readMappings(applicationId: string, indexName: string) {
    return this.axiosInstance.get(
      `/${applicationId}/indices/${indexName}/mappings`
    );
  }

  async updateMappings(
    applicationId: string,
    indexName: string,
    payload: IndexMappingUpdateMany
  ) {
    return this.axiosInstance.put(
      `/${applicationId}/indices/${indexName}/mappings`,
      payload
    );
  }

  async readFields(applicationId: string, indexName: string) {
    return this.axiosInstance.get(
      `/${applicationId}/indices/${indexName}/fields`
    );
  }

  async readFieldBuckets(
    applicationId: string,
    indexName: string,
    fields: string[]
  ) {
    return this.axiosInstance.get(
      `/${applicationId}/indices/${indexName}/fields/buckets`,
      {
        params: {
          fields: fields.join(","),
        },
      }
    );
  }

  async delete(applicationId: string, indexName: string) {
    return this.axiosInstance.delete(`/${applicationId}/indices/${indexName}`);
  }

  async syncDictionary(applicationId: string, indexName: string, target: string) {
    return this.axiosInstance.put(`/${applicationId}/indices/${indexName}/${target}/_sync`);
  }
}

export default new Indices();
