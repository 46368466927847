import { makeAutoObservable } from "mobx";
import _ from "lodash";
import repository from "src/repositories/Document";
import searchRepository from "src/repositories/Search";
import indexRepository from "src/repositories/Indices";
import {
  DisplayItem,
  Dict,
  DSLItem,
  Entrance,
  SearchMethodType,
  SelectType,
  StoreState,
  IndexJob,
} from "src/types/common";
import { DISPLAY_ITEMS, SORT } from "src/constants";
import { Index } from "src/types/index/model";
import { IndexFieldResponse } from "src/types/index/schema";
import { PythonDSL } from "src/types/python-dsl/model";
import { Demo } from "src/types/demo/model";
import {
  SingleDocumentCreate,
  SingleDocumentUpdate,
} from "src/types/document/schema";
import {
  RangeFilter,
  RankingRule,
  SearchCriteria,
  SearchResponse,
  SearchRule,
  SortDictType,
  TermFilter,
} from "src/types/search/schema";

export default class DocumentList {
  state: StoreState = "none";
  dslState: StoreState = "none";
  job?: IndexJob;
  entrance: Entrance = "browse";
  searchMethod: SearchMethodType = "gui";
  subTab: "searchRule" | "scoreRanking" | "highlighting" = "searchRule";
  result?: SearchResponse;
  fields?: IndexFieldResponse;
  dslResult?: SearchResponse;
  sortingOptions?: SelectType[];
  displayItems: DisplayItem[] = [];
  originDisplayItems: DisplayItem[] = [];
  query: string = "";
  dslQuery: DSLItem[] = [
    { key: "", value: "" },
    { key: "", value: "" },
  ];
  page: number = 1;
  size: number = 10;
  range_filters: RangeFilter[] = [];
  terms_filters: TermFilter[] = [];
  search_rules: SearchRule[] = [];
  ranking_rules: RankingRule[] = [];
  sort_rules: SortDictType[] = [];
  use_bannedword?: boolean;

  // have not yet considered

  field_value_factor?: Dict;
  date_decay_weight?: Dict;
  geo_decay_weight?: Dict;
  numeric_decay_weight?: Dict;
  script_score?: Dict;

  highlight_options?: Dict;

  constructor() {
    makeAutoObservable(this);
  }

  async setSearchMethod(method: SearchMethodType) {
    this.searchMethod = method;
  }

  setSubTab(subTab: "searchRule" | "scoreRanking" | "highlighting") {
    this.subTab = subTab;
  }

  async setQuery(query?: string) {
    if (!query) query = "";
    this.query = query;
  }

  async setPage(page?: number) {
    if (!page) page = 1;
    this.page = page;
  }

  async setSize(size?: number) {
    if (!size) size = 10;
    this.size = size;
  }

  async setSortOptions(option: SortDictType) {
    this.sort_rules = [option];
  }

  async setDSLQuerys(dslItems: DSLItem[]) {
    this.dslQuery = dslItems;
  }

  async setDSLQuery(index: number, key?: string, value?: string) {
    if (key) this.dslQuery[index].key = key;
    if (value) this.dslQuery[index].value = value;
  }

  async setMultiSortOptions(option: SortDictType) {
    // 다중 소팅 시
    if (this.sort_rules.some((r) => r.field === option.field))
      this.sort_rules = this.sort_rules.filter((r) => r !== option);
    else this.sort_rules.push(option);
  }

  async setDisplayItems(displayFields: string[]) {
    if (!this.result!.hits) return;
    if (displayFields.length === 0)
      displayFields = this.fields!.parent_fields.map((f) => f.name!);
    this.displayItems = DISPLAY_ITEMS(
      displayFields,
      this.fields!.parent_fields
    );
    this.originDisplayItems = _.cloneDeep(this.displayItems);
  }

  async setTermFilters(field: string, terms: string[]) {
    if (this.terms_filters.some((f) => f.field === field))
      this.terms_filters = this.terms_filters.filter((f) => f.field !== field);
    if (terms.length === 0) return;
    this.terms_filters.push({ field: field, terms: terms });
  }

  async setRangeFilters(
    field: string,
    gte: string | number,
    lte: string | number
  ) {
    if (this.range_filters.some((f) => f.field === field))
      this.range_filters = this.range_filters.filter((f) => f.field !== field);
    this.range_filters.push({ field: field, gte: gte, lte: lte });
  }

  async popRangeFilter(field: string) {
    this.range_filters = this.range_filters.filter((f) => f.field !== field);
  }

  getSortingOption() {
    if (this.sort_rules.length <= 0) return;

    return this.sortingOptions!.filter(
      (o) => o.value.field === this.sort_rules[0].field).filter((o) => o.value.order === this.sort_rules[0].order
      )[0];
  }

  async popDisplayItem(index: number) {
    return this.displayItems.splice(index, 1);
  }

  async pushDisplayItem(index: number, item: DisplayItem) {
    this.displayItems.splice(index, 0, item);
  }

  async turnDisplayOnOff(index: number) {
    this.displayItems[index].display = !this.displayItems[index].display;
    // clone 하지 않으면 리랜더링이 안 일어남
    this.displayItems = _.clone(this.displayItems);
  }

  displayHit(hit: Dict) {
    const result: Dict = {};
    this.originDisplayItems.forEach((item) => {
      if (hit[item.value] !== "" && !_.isUndefined(hit[item.value]))
        result[item.value] = hit[item.value];
    });
    return result;
  }

  filterHit(hit: Dict) {
    const result: Dict = {};
    this.originDisplayItems.forEach((item) => {
      if (item.display && !_.isUndefined(hit[item.value])) result[item.value] = hit[item.value];
    });
    return result;
  }

  async resetDisplay() {
    this.displayItems = _.cloneDeep(this.originDisplayItems);
  }

  async getDisplayFields() {
    return this.displayItems.filter((i) => i.display).map((i) => i.value);
  }

  async updateSortFields(filters: string[]) {
    if (!filters.length) {
      this.terms_filters = [];
      this.range_filters = [];
      return;
    }
    this.terms_filters = this.terms_filters.filter((f) =>
      filters.includes(f.field)
    );
    this.range_filters = this.range_filters.filter((f) =>
      filters.includes(f.field)
    );
  }

  async updateFilterFields(sorts: string[]) {
    if (!sorts.length) {
      this.sort_rules = [];
      return;
    }
    this.sort_rules = this.sort_rules.filter((r) => sorts.includes(r.field));
  }

  async initializeSortingOptions(index: Index) {
    this.sortingOptions = SORT(
      this.fields!.aggregatable_fields.filter((f) =>
        index.sorting_fields!.some((s) => s === f.name)
      )
    );
  }

  async initializeDemoSortingOptions(demoPayload: Demo) {
    if (demoPayload.sorting_fields) {
      if (!demoPayload.sorting_fields) this.sortingOptions = [];
      this.sortingOptions = SORT(
        this.fields!.aggregatable_fields.filter((f) =>
          demoPayload.sorting_fields!.some((s) => s === f.name)
        )
      );
    }
  }

  async getDemoFields(demoPayload: Demo) {
    const fields: string[] = [];
    if (demoPayload.content_fields)
      demoPayload.content_fields.forEach((f) => fields.push(f));
    if (demoPayload.title_field) fields.push(demoPayload.title_field);
    return fields ? fields : undefined;
  }

  async getPayload(demoPayload?: Demo) {
    return {
      query: this.query,
      page: this.page,
      size: this.size,
      sort_rules: this.sort_rules,
      include: demoPayload ? await this.getDemoFields(demoPayload) : undefined,
      range_filters: this.range_filters,
      terms_filters: this.terms_filters,
      search_rules: this.search_rules,
      ranking_rules: this.ranking_rules,
      use_bannedword: this.use_bannedword,
      highlight_options: this.highlight_options
    };
  }

  async readMany(
    applicationId: string,
    index: Index,
    entrance: Entrance,
    pythonDSL?: PythonDSL,
    demoPayload?: Demo,
    payload?: SearchCriteria
  ) {
    this.state = "pending";
    this.entrance = entrance;
    try {
      if (!this.fields) await this.readFields(applicationId, index.name);
      if (entrance === "browse") {
        await this.initializeSortingOptions(index);
        if (!this.sort_rules.length && this.sortingOptions?.length)
          await this.setSortOptions(this.sortingOptions[0].value);
      }
      if (entrance === "demo") {
        await this.initializeDemoSortingOptions(demoPayload!);
        if (!this.sort_rules.length && this.sortingOptions?.length)
          await this.setSortOptions(this.sortingOptions[0].value);
      }
      if (entrance.includes("search-setting")) {
        this.search_rules = index.search_rules!;
        this.ranking_rules = index.ranking_rules!;
        this.use_bannedword = index.use_bannedword!;
        this.highlight_options = index.highlight_options!;
      }
      if (entrance === "search-setting-dsl") {
        await this.readDSLMany(applicationId, index, pythonDSL!);
      } else {
        if (!payload)
          payload = await this.getPayload(
            demoPayload ? demoPayload : undefined
          );
        const response = await searchRepository.read(
          applicationId,
          index.name,
          payload
        );
        this.result = response.data;
      }
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async readFields(applicationId: string, indexName: string) {
    try {
      const response = await indexRepository.readFields(
        applicationId,
        indexName
      );
      this.fields = response.data;
    } catch (e) {
      throw e;
    }
  }

  async getDSLFunctionParams() {
    const functionParams: Dict = {};
    this.dslQuery.forEach((query) => {
      if (query.key) functionParams[query.key] = query.value;
    });
    return functionParams;
  }

  async readDSLMany(applicationId: string, index: Index, pythonDSL: PythonDSL) {
    this.dslState = "pending";
    this.state = "pending";
    try {
      const functionParams = await this.getDSLFunctionParams();
      const response = await searchRepository.readDSL(
        applicationId,
        index.name,
        pythonDSL._id,
        {
          page: this.page,
          size: this.size,
          function_params: functionParams,
          python_dsl: pythonDSL.dsl_function,
        }
      );
      this.dslResult = response.data;
      this.dslState = "done";
      this.state = "done";
    } catch (e) {
      this.dslState = "error";
      this.state = "error";
      throw e;
    }
  }

  async create(
    applicationId: string,
    indexName: string,
    payload: SingleDocumentCreate
  ) {
    this.job = "createDocument";
    try {
      await repository.create(applicationId, indexName, payload);
      this.job = undefined;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async read(applicationId: string, indexName: string, documentId: string) {
    try {
      const response = await repository.read(
        applicationId,
        indexName,
        documentId
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(applicationId: string, indexName: string, documentId: string) {
    this.job = "deleteDocument";
    try {
      await repository.delete(applicationId, indexName, documentId);
      await this.popDocument(documentId);
      this.job = undefined;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async popDocument(documentId: string) {
    if (this.result) {
      this.result!.hits = this.result!.hits.filter((h) => h._id !== documentId);
      this.result!.total = this.result!.total - 1;
    }
  }

  async update(
    applicationId: string,
    indexName: string,
    documentId: string,
    payload: SingleDocumentUpdate
  ) {
    this.job = "updateDocument";
    try {
      await repository.update(applicationId, indexName, documentId, payload);
      this.job = undefined;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async reindex(applicationId: string, indexName: string) {
    this.job = "reindex";
    try {
      await repository.reindex(applicationId, indexName);
      this.job = undefined;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async upload(applicationId: string, indexName: string, payload: FormData) {
    this.job = "uploadDocuments";
    try {
      await repository.upload(applicationId, indexName, payload);
      this.job = undefined;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async clearDSL() {
    this.dslState = "none";
    this.dslQuery = [
      { key: "", value: "" },
      { key: "", value: "" },
    ];
    this.dslResult = undefined;
    this.page = 1;
    this.size = 10;
  }

  async clearGUI() {
    this.result = undefined;
    this.query = "";
    this.page = 1;
    this.size = 10;
    this.search_rules = [];
    this.ranking_rules = [];
  }

  async clear() {
    this.state = "none";
    this.dslState = "none";
    this.job = undefined;
    this.entrance = "browse";
    this.searchMethod = "gui";
    this.subTab = "searchRule";
    this.result = undefined;
    this.fields = undefined;
    this.sortingOptions = [];
    this.displayItems = [];
    this.originDisplayItems = [];
    this.query = "";
    this.dslQuery = [
      { key: "", value: "" },
      { key: "", value: "" },
    ];
    this.page = 1;
    this.size = 10;
    this.range_filters = [];
    this.terms_filters = [];
    this.search_rules = [];
    this.ranking_rules = [];
    this.sort_rules = [];
  }

  async setReady() {
    this.state = "none";
  }
}
