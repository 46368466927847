import { makeAutoObservable } from "mobx";
import repository from "src/repositories/Synonym";
import { StoreState, SynonymField } from "src/types/common";
import { Synonym } from "src/types/synonym/model";
import {
  SynonymCreate,
  SynonymFind,
  SynonymUpdate,
  SynonymDelete,
  SynonymListResponse,
} from "src/types/synonym/schema";

class SynonymList {
  state: StoreState = "none";
  domainState: StoreState = "none";
  synonymList: Synonym[] = [];
  synonym?: Synonym;
  searchQuery: string = "";
  domain: string = "전체";
  domainList: string[] = ["공통"];
  searchTarget: SynonymField = { en: "all", ko: "전체" };
  page: number = 1;
  size: number = 20;
  deleteIdList: string[] = [];
  totalCount: number = 0;
  totalPage: number = 1;
  sort: string[] = ["-created_dt"];

  constructor() {
    makeAutoObservable(this);
  }

  setSynonym(synonym?: Synonym) {
    this.synonym = synonym;
  }

  setSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }

  setDomain(domain: string) {
    this.domain = domain;
  }

  setSearchTarget(searchTarget: SynonymField) {
    this.searchTarget = searchTarget;
  }

  setPage(page: number) {
    this.page = page;
  }

  setDeleteIdList(list: string[]) {
    this.deleteIdList = list;
  }

  setSort(sort: string[]) {
    this.sort = sort;
  }

  async getDomainList(applicationId: string) {
    this.domainState = "pending";
    try {
      const response = await repository.getDomains(applicationId);
      this.domainList = response.data.domains;
      if (!this.domainList.includes("공통")) this.domainList.unshift("공통");
      this.domainState = "done";
    } catch (e) {
      this.domainState = "error";
      throw e;
    }
  }

  async search(applicationId: string) {
    this.state = "pending";

    const payload: SynonymFind = {
      query: this.searchQuery,
      domain: this.domain === "전체" ? "all" : this.domain,
      target: this.searchTarget.en,
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      const response = await repository.search(applicationId, payload);
      const data: SynonymListResponse = response.data;

      this.synonymList = data.synonyms;
      this.totalCount = data.total;
      this.totalPage = data.total_page;

      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async create(applicationId: string, payload: SynonymCreate) {
    await repository.create(applicationId, payload);
  }

  async upload(applicationId: string, formData: FormData) {
    await repository.upload(applicationId, formData);
  }

  async update(
    applicationId: string,
    synonym_id: string,
    payload: SynonymUpdate
  ) {
    await repository.update(applicationId, synonym_id, payload);
  }

  async delete(applicationId: string, payload: SynonymDelete) {
    await repository.delete(applicationId, payload);
  }

  clear() {
    this.state = "none";
    this.domainState = "none";
    this.synonymList = [];
    this.synonym = undefined;
    this.searchQuery = "";
    this.domain = "전체";
    this.domainList = ["공통"];
    this.searchTarget = { en: "all", ko: "전체" };
    this.page = 1;
    this.deleteIdList = [];
    this.totalCount = 0;
    this.totalPage = 1;
    this.sort = ["-created_dt"];
  }

  setReady() {
    this.state = "none";
  }
}

export default SynonymList;
