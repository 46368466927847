import { makeAutoObservable } from "mobx";

import repository from "src/repositories/Dashboard";
import { IndexStatus } from "src/types/index-status/model";
import { DashboardIndexInfoRequest } from "src/types/index-status/schema";
import { StoreState, LineGraphType, SelectType } from "src/types/common";

import { initializeGte, initializeLte, makeDateString } from "src/utils";

const NINE_HOURS = 32400000;
export default class IndexDashboard {
  state: StoreState = "none";
  selectedIndex?: IndexStatus;
  gte: Date = initializeGte();
  lte: Date = initializeLte();
  interval: SelectType = { value: "4h", label: "4시간" };
  searchCountGraphData: LineGraphType[] = [];
  searchSpeedGraphData: LineGraphType[] = [];
  health: "green" | "yellow" | "red" = "green";

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIndex(indexStatus?: IndexStatus) {
    this.selectedIndex = indexStatus;
  }

  setGte(gte: Date) {
    this.gte = gte;
  }

  setLte(lte: Date) {
    this.lte = lte;
  }

  setInterval(interval: SelectType) {
    this.interval = interval;
  }

  setHealth(health: "green" | "yellow" | "red") {
    this.health = health;
  }

  setReady() {
    this.state = "none";
  }

  async getSearchCount(applicationId: string, indexName: string) {
    this.searchCountGraphData = [];

    const payload: DashboardIndexInfoRequest = {
      gte: makeDateString(this.gte),
      lte: makeDateString(this.lte),
      index_name: indexName,
      interval: this.interval.value,
    };

    const response = await repository.getSearchCount(applicationId, payload);

    const arr: { timestamp: string; count: number }[] = response.data.response;
    const temp: any[] = [];
    const graphData: LineGraphType = {
      id: indexName,
      data: [],
    };

    arr.forEach((item) => {
      temp.push({
        x: new Date(Date.parse(item.timestamp) - NINE_HOURS),
        y: item.count,
      });
    });

    graphData.data = temp;

    this.searchCountGraphData.push(graphData);

    //* For Test. It should be removed.
    // const days = getDifferenceDays(this.lte, this.gte);
    // console.log(`days is ${days}`);
    // let newData = [...bulkData.data];
    // newData.splice(days * 24);

    // console.log(newData);

    // switch (this.interval.value) {
    //   case "2h":
    //     newData = newData.filter((item, index) => index % 2 === 0);
    //     break;

    //   case "3h":
    //     newData = newData.filter((item, index) => index % 3 === 0);
    //     break;

    //   case "4h":
    //     newData = newData.filter((item, index) => index % 4 === 0);
    //     break;

    //   case "6h":
    //     newData = newData.filter((item, index) => index % 6 === 0);
    //     break;

    //   case "8h":
    //     newData = newData.filter((item, index) => index % 8 === 0);
    //     break;

    //   case "12h":
    //     newData = newData.filter((item, index) => index % 12 === 0);
    //     break;

    //   case "1d":
    //     newData = newData.filter((item, index) => index % 24 === 0);
    //     break;
    // }

    // this.searchCountGraphData.push({ id: "test", data: newData });
  }

  async getSearchSpeed(applicationId: string, indexName: string) {
    this.searchSpeedGraphData = [];

    const payload: DashboardIndexInfoRequest = {
      gte: makeDateString(this.gte),
      lte: makeDateString(this.lte),
      index_name: indexName,
      interval: this.interval.value,
    };

    const response = await repository.getSearchSpeed(applicationId, payload);
    const arr: { timestamp: string; speed: number | null }[] =
      response.data.response;
    const temp: any[] = [];
    const graphData: LineGraphType = {
      id: indexName,
      data: [],
    };

    arr.forEach((item) => {
      temp.push({
        x: new Date(Date.parse(item.timestamp) - NINE_HOURS),
        y: item.speed ? item.speed.toFixed(4) : null,
      });
    });
    graphData.data = temp;

    this.searchSpeedGraphData.push(graphData);
  }

  async getGraphData(applicationId: string, indexName: string) {
    this.state = "pending";

    try {
      this.getSearchCount(applicationId, indexName);
      await this.getSearchSpeed(applicationId, indexName);

      this.state = "done";
    } catch (e) {
      this.state = "error";
    }
  }

  clear() {
    this.state = "none";
    this.selectedIndex = undefined;
    this.gte = initializeGte();
    this.lte = initializeLte();
  }
}
