import { makeAutoObservable } from "mobx";
import _ from "lodash";
import repository from "src/repositories/Demo";
import IndexRepository from "src/repositories/Indices";
import { Dict, StoreState } from "src/types/common";
import { Demo as Model } from "src/types/demo/model";
import { DemoUpdate } from "src/types/demo/schema";

export default class Demo {
  state: StoreState = "none";
  demo?: Model;
  fieldBuckets?: Dict;

  constructor() {
    makeAutoObservable(this);
  }

  async read(applicationId: string, indexName: string) {
    this.state = "pending";
    try {
      const response = await repository.read(applicationId, indexName);
      this.demo = response.data ? response.data : undefined;
      await this.readFieldBuckets(applicationId, indexName);
      this.state = "done";
      return this.demo;
    } catch (e) {
      this.state = "error";
      console.error(e);
      // throw e;
    }
  }

  async readFieldBuckets(applicationId: string, indexName: string) {
    try {
      if (this.demo?.filter_fields) {
        const response = await IndexRepository.readFieldBuckets(
          applicationId,
          indexName,
          this.demo.filter_fields
        );
        this.fieldBuckets = response.data.buckets;
      }
    } catch (e) {
      throw e;
    }
  }

  async setDemo(demo: Model) {
    this.demo = demo;
  }

  getWithUndefined(value: any) {
    if (typeof value === "boolean") return value;
    return value ? value : undefined;
  }

  getComparePayload(): DemoUpdate {
    return _.cloneDeep({
      title_field: this.demo?.title_field,
      content_fields: this.getWithUndefined(this.demo?.content_fields),
      word_limit: this.getWithUndefined(this.demo?.word_limit),
      highlight: this.getWithUndefined(this.demo?.highlight),
      sorting_fields: this.getWithUndefined(this.demo?.sorting_fields),
      filter_fields: this.getWithUndefined(this.demo?.filter_fields),
      url_fields: this.getWithUndefined(this.demo?.url_fields),
      demo_name: this.getWithUndefined(this.demo?.demo_name),
      demo_logo_url: this.getWithUndefined(this.demo?.demo_logo_url),
    });
  }

  async compareBeforeUpdate(payload: DemoUpdate) {
    if (!this.demo) return false;
    const origin = this.getComparePayload();
    return _.isEqual(origin, payload);
  }

  async update(applicationId: string, indexName: string, payload: DemoUpdate) {
    try {
      if (await this.compareBeforeUpdate(payload)) return undefined;
      const response = await repository.update(applicationId, indexName, payload);
      return response.data;
    } catch (e) {
      console.error(e);
      // throw e;
    }
  }

  async clear() {
    this.state = "none";
    this.demo = undefined;
    this.fieldBuckets = undefined;
  }
}
