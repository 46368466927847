import { makeAutoObservable } from "mobx";
import repository from "src/repositories/Dashboard";
import { StoreState } from "src/types/common";
import { Node } from "src/types/node/model";

export default class NodeDashboard {
  state: StoreState = "none";
  nodeStatusList: Node[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get totalNode() {
    return this.nodeStatusList.length;
  }

  get totalHeapCurrent() {
    let total = 0;

    this.nodeStatusList.forEach((node) => {
      total += node.heap_current;
    });

    return total;
  }

  get totalHeapMax() {
    let total = 0;

    this.nodeStatusList.forEach((node) => {
      total += node.heap_max;
    });

    return total;
  }

  get totalDiskUsed() {
    let total = 0;

    this.nodeStatusList.forEach((node) => {
      total += node.disk_used;
    });

    return total;
  }

  get totalDiskMax() {
    let total = 0;

    this.nodeStatusList.forEach((node) => {
      total += node.disk_total;
    });

    return total;
  }

  get totalRamCurrent() {
    let total = 0;

    this.nodeStatusList.forEach((node) => {
      total += node.ram_current;
    });

    return total;
  }

  get totalRamMax() {
    let total = 0;

    this.nodeStatusList.forEach((node) => {
      total += node.ram_max;
    });

    return total;
  }

  async getList(applicationId: string) {
    this.state = "pending";

    try {
      const response = await repository.getNodes(applicationId);

      this.nodeStatusList = response.data.response;
      this.state = "done";
    } catch (e) {
      this.state = "error";
    }
  }
}
