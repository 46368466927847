import { makeAutoObservable } from "mobx";
import repository from "src/repositories/AutoCplDict";
import { StoreState, AutoCPLStatus } from "src/types/common";
import { AutoCPLDict } from "src/types/auto-complete-dict/model";
import {
  AutoCPLDictCreate,
  AutoCPLDictUpdate,
  AutoCPLDictDelete,
  AutoCPLDictFind,
  AutoCPLDictListResponse,
} from "src/types/auto-complete-dict/schema";

class AutoCplDictList {
  state: StoreState = "none";
  autoDictStatus: AutoCPLStatus = "all";
  autoDictList: AutoCPLDict[] = [];
  autoDict?: AutoCPLDict;
  searchQuery: string = "";
  page: number = 1;
  size: number = 20;
  deleteKeywordList: string[] = [];
  totalCount: number = 0;
  totalPage: number = 1;
  sort: string = "-created_dt";

  constructor() {
    makeAutoObservable(this);
  }

  setAutoDictList(autoDictList: AutoCPLDict[]) {
    this.autoDictList = autoDictList;
  }

  setAutoDict(autoDict?: AutoCPLDict) {
    this.autoDict = autoDict;
  }

  setSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }

  setPage(page: number) {
    this.page = page;
  }

  setDeleteKeywordList(list: string[]) {
    this.deleteKeywordList = list;
  }

  setSort(sort: string) {
    this.sort = sort;
  }

  setStatus(status: AutoCPLStatus) {
    this.autoDictStatus = status;
  }

  getPage() {
    return this.page;
  }

  async read_many(applicationId: string) {
    this.state = "pending";

    const params: AutoCPLDictFind = {
      query: this.searchQuery,
      page: this.page,
      size: this.size,
      sort: this.sort,
      status: this.autoDictStatus === "all" ? undefined : this.autoDictStatus,
    };

    try {
      const response = await repository.read_many(applicationId, params);
      const data: AutoCPLDictListResponse = response.data;

      this.autoDictList = data.auto_dicts;
      this.totalCount = data.total;
      this.totalPage = data.total_page;

      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async create(applicationId: string, payload: AutoCPLDictCreate) {
    await repository.create(applicationId, payload);
  }

  async upload(applicationId: string, formData: FormData) {
    await repository.upload(applicationId, formData);
  }

  async update(
    applicationId: string,
    autocpl_id: string,
    payload: AutoCPLDictUpdate
  ) {
    await repository.update(applicationId, autocpl_id, payload);
  }

  async delete_many(applicationId: string, payload: AutoCPLDictDelete) {
    await repository.delete_many(applicationId, payload);
  }

  clear() {
    this.state = "none";
    this.autoDictStatus = "all";
    this.autoDictList = [];
    this.autoDict = undefined;
    this.searchQuery = "";
    this.page = 1;
    this.deleteKeywordList = [];
    this.totalCount = 0;
    this.totalPage = 1;
    this.sort = "-created_dt";
  }

  setReady() {
    this.state = "none";
  }
}

export default AutoCplDictList;
