import { makeAutoObservable } from "mobx";
import repository from "src/repositories/StopWord";
import { StoreState } from "src/types/common";
import { StopWord } from "src/types/stopword/model";
import { StopWordCreate } from "src/types/stopword/schema";

export default class StopwordList {
  state: StoreState = "none";
  stopwords: StopWord[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(applicationId: string, indexName: string) {
    this.state = "pending";
    try {
      const response = await repository.readMany(applicationId, indexName);
      this.stopwords = response.data.stopwords ? response.data.stopwords : [];
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async justReadMany(applicationId: string, indexName: string) {
    try {
      const response = await repository.readMany(applicationId, indexName);
      this.stopwords = response.data.stopwords ? response.data.stopwords : [];
    } catch (e) {
      throw e;
    }
  }

  async create(
    applicationId: string,
    indexName: string,
    payload: StopWordCreate
  ) {
    try {
      await repository.create(applicationId, indexName, payload);
    } catch (e) {
      throw e;
    }
  }

  async delete(applicationId: string, indexName: string, stopWordId: string) {
    try {
      await repository.delete(applicationId, indexName, stopWordId);
    } catch (e) {
      throw e;
    }
  }

  async clear() {
    this.state = "none";
    this.stopwords = [];
  }
}
