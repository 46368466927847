import { makeAutoObservable } from "mobx";
import _ from "lodash";
import repository from "src/repositories/Member";
import { StoreState } from "src/types/common";
import { Member as Model } from "src/types/member/model";
import { MemberCreate, MemberUpdate } from "src/types/member/schema";

export default class Member {
  state: StoreState = "none";
  member?: Model;

  constructor() {
    makeAutoObservable(this);
  }

  async set(member?: Model) {
    this.state = "pending";
    if (member) {
      this.member = _.clone(member);
    } else {
      this.member = undefined;
    }
    this.state = "done";
  }

  async create(payload: MemberCreate) {
    try {
      await repository.create(payload);
    } catch (e) {
      throw e;
    }
  }

  async update(memberId: string, payload: MemberUpdate) {
    try {
      await repository.update(memberId, payload);
    } catch (e) {
      throw e;
    }
  }

  async delete(memberId: string) {
    try {
      await repository.delete(memberId);
    } catch (e) {
      throw e;
    }
  }

  async clear() {
    this.member = undefined;
    this.state = "none";
  }
}
