import React from "react";

import UserList from "src/stores/UserList";
import Member from "src/stores/Member";
import Authentication from "src/stores/Authentication";
import ApplicationList from "src/stores/ApplicationList";
import ActiveApplication from "src/stores/ActiveApplication";
import IndexList from "src/stores/IndexList";
import ActiveIndex from "src/stores/ActiveIndex";
import TokenList from "src/stores/TokenList";
import DocumentList from "src/stores/DocumentList";
import Demo from "src/stores/Demo";
import ActiveDocument from "src/stores/ActiveDocument";
import BannedwordList from "src/stores/BannedWordList";
import Mappings from "src/stores/Mappings";
import Analysis from "src/stores/Analysis";
import SynonymList from "src/stores/SynonymList";
import UserdictList from "src/stores/UserdictList";
import CsvFile from "src/stores/CsvFile";
import Dashboard from "src/stores/Dashboard";
import LogList from "src/stores/LogList";
import StopwordList from "src/stores/StopwordList";
import NewLogList from "src/stores/LogList";
import NodeDashboard from "src/stores/NodeDashboard";
import IndexDashboard from "src/stores/IndexDashboard";
import IndexStatus from "src/stores/IndexStatus";
import AuditLogList from "src/stores/AuditLogList";
import Policy from "src/stores/Policy";
import AutocompleteList from "./AutocompleteList";
import AutoCplDictList from "src/stores/AutoCplDictList";
import AutoCplSearch from "src/stores/AutoCplSearch";
import TrendList from "src/stores/TrendList";
import PopularTermList from "src/stores/PopularTermList";

const storeContext = React.createContext({
  userList: new UserList(),
  member: new Member(),
  authentication: new Authentication(),
  applicationList: new ApplicationList(),
  activeApplication: new ActiveApplication(),
  indexList: new IndexList(),
  activeIndex: new ActiveIndex(),
  tokenList: new TokenList(),
  mappings: new Mappings(),
  analysis: new Analysis(),
  documentList: new DocumentList(),
  demo: new Demo(),
  activeDocument: new ActiveDocument(),
  bannedWordList: new BannedwordList(),
  synonymList: new SynonymList(),
  userdictList: new UserdictList(),
  csvFile: new CsvFile(),
  dashboard: new Dashboard(),
  stopwordList: new StopwordList(),
  logList: new LogList(),
  newLogList: new NewLogList(),
  nodeDashboard: new NodeDashboard(),
  indexDashboard: new IndexDashboard(),
  indexStatus: new IndexStatus(),
  auditLogList: new AuditLogList(),
  policy: new Policy(),
  autocompleteList: new AutocompleteList(),
  autoCplDictList: new AutoCplDictList(),
  autoCplSearch: new AutoCplSearch(),
  trendList: new TrendList(),
  popularTermList: new PopularTermList(),
});

export default storeContext;
