import { makeAutoObservable } from "mobx";
import _ from "lodash";

import repository from "src/repositories/LogList";
import { StoreState, SearchLogType, SelectType } from "src/types/common";

export type FilterField =
  | "term"
  | "team"
  | "user_identity"
  | "index_name"
  | "response_latency"
  | "total"
  | "timestamp";

export interface LogFilter {
  field: FilterField | string;
  value?: string;
  gte?: string | number;
  lte?: string | number;
  order?: "desc" | "asc";
}

export interface StringFilterOptions {
  value?: string;
  exec?: boolean;
}

export interface NumberFilterOptions {
  gte?: number | string;
  lte?: number | string;
}

export interface FilterType {
  [key: string]: any;
  term?: StringFilterOptions;
  team?: StringFilterOptions;
  user_identity?: StringFilterOptions;
  index_name?: StringFilterOptions;
  response_latency?: NumberFilterOptions;
  total?: NumberFilterOptions;
  timestamp?: NumberFilterOptions;
}

export interface SearchTerm {
  term: string;
  count: number;
  latest_hits_count: number;
}

export interface SortType {
  field: FilterField;
  order: "desc" | "asc";
}

const stringFilters = ["term", "team", "user_identity", "index_name"];

const SORT_ITEMS = [
  {
    value: {
      en: "term",
      display: true,
    },
    label: "검색어",
  },
  { value: { en: "team", display: true }, label: "팀" },
  { value: { en: "user_identity", display: true }, label: "유저" },
  { value: { en: "index_name", display: true }, label: "인덱스 이름" },
  { value: { en: "response_latency", display: true }, label: "응답 레이턴시" },
  { value: { en: "total", display: true }, label: "검색 결과수" },
  { value: { en: "timestamp", display: true }, label: "날짜" },
];

export default class NewLogList {
  state: StoreState = "none";
  scrollState: StoreState = "none";
  termsState: StoreState = "none";
  isMinimized: boolean = false;
  logList: SearchLogType[] = [];
  selectedRequestBody: string = "";
  searchTerms: SearchTerm[] = [];
  cardinality: number = 0;
  page: number = 1;

  size: number = 20;
  filters: FilterType = {};
  initialSortList: SelectType[] = SORT_ITEMS;
  sort: SortType[] = [];
  pit?: any;
  search_after?: any;

  constructor() {
    makeAutoObservable(this);
  }

  get searchTermsFrom() {
    return 10 * (this.page - 1);
  }

  get lastPage() {
    return Math.ceil(this.cardinality / 10);
  }

  toggleIsMinimized() {
    if (this.isMinimized) this.isMinimized = false;
    else this.isMinimized = true;
  }

  setSelectedRequestBody(value: string) {
    this.selectedRequestBody = JSON.stringify(JSON.parse(value), null, 4);
  }

  setFilters(newFilters: FilterType) {
    this.filters = newFilters;
  }

  increasePage() {
    if (this.termsState === "done" && this.page + 1 <= this.lastPage) {
      this.page++;
      this.termsState = "none";
    }
  }

  decreasePage() {
    if (this.termsState === "done" && this.page - 1 >= 1) {
      this.page--;
      this.termsState = "none";
    }
  }

  addFilter(data: LogFilter) {
    if (stringFilters.some((item) => item === data.field)) {
      this.filters = {
        ...this.filters,
        [data.field]: {
          value: data.value,
        },
      };
    } else {
      if (data.gte && data.lte) {
        this.filters = {
          ...this.filters,
          [data.field]: {
            gte: data.gte,
            lte: data.lte,
          },
        };
      } else if (data.gte) {
        this.filters = {
          ...this.filters,
          [data.field]: {
            ...this.filters[data.field],
            gte: data.gte,
          },
        };
      } else if (data.lte) {
        this.filters = {
          ...this.filters,
          [data.field]: {
            ...this.filters[data.field],
            lte: data.lte,
          },
        };
      }
    }
  }

  addOrder(field: FilterField, order: "asc" | "desc") {
    this.sort = [...this.sort, { field, order }];
    this.initialSortList.forEach((item) => {
      if (item.value.en === field) item.value.display = false;
    });

    this.setReady();
  }

  deleteOrder(field: FilterField) {
    this.sort = _.cloneDeep(this.sort.filter((item) => item.field !== field));
    this.initialSortList.forEach((item) => {
      if (item.value.en === field) item.value.display = true;
    });

    this.setReady();
  }

  makeAsc(field: FilterField) {
    const newSort: SortType[] = this.sort.map((item) => {
      if (item.field === field) item.order = "asc";
      return item;
    });

    this.sort = newSort;
    this.setReady();
  }

  makeDesc(field: FilterField) {
    const newSort: SortType[] = this.sort.map((item) => {
      if (item.field === field) item.order = "desc";
      return item;
    });

    this.sort = newSort;
    this.setReady();
  }

  deleteFilter(
    field: FilterField,
    target?: "gte" | "lte",
    value?: number | string
  ) {
    if (target) {
      if (target === "gte") this.filters[field] = { lte: value };
      else this.filters[field] = { gte: value };
    } else delete this.filters[field];
  }

  isFilterExist(field: FilterField) {
    if (this.filters[field]) return true;
    else return false;
  }

  async getLogs(applicationId: string) {
    this.state = "pending";

    const payload = {
      size: this.size,
      filters: this.filters,
      sort: this.sort,
    };

    try {
      const response = await repository.getLogs(applicationId, payload);

      this.logList = response.data.logs;
      this.pit = response.data.pit;
      this.search_after = response.data.search_after;
      this.state = "done";
    } catch (e) {
      this.state = "error";
    }
  }

  async getNextLogs(applicationId: string) {
    this.scrollState = "pending";

    const payload = {
      size: this.size,
      pit: this.pit,
      search_after: this.search_after,
      filters: this.filters,
      sort: this.sort,
    };

    try {
      const response = await repository.getLogs(applicationId, payload);

      this.logList = [...this.logList, ...response.data.logs];
      this.pit = response.data.pit;
      this.search_after = response.data.search_after;
      this.scrollState = "done";
    } catch (e) {
      this.scrollState = "error";
    }
  }

  async getSearchTerms(applicationId: string) {
    this.termsState = "pending";

    const payload = {
      from: this.searchTermsFrom,
      size: 10,
      order: "desc",
      filters: this.filters,
    };

    try {
      const response = await repository.getSearchTerms(applicationId, payload);

      this.searchTerms = response.data.terms;
      this.cardinality = response.data.cardinality;
      this.termsState = "done";
    } catch (e) {
      this.termsState = "error";
    }
  }

  setReady() {
    this.state = "none";
    this.termsState = "none";
    this.page = 1;
  }

  clearFilters() {
    this.filters = {};
  }

  clear() {
    this.state = "none";
    this.termsState = "none";
    this.scrollState = "none";
    this.isMinimized = false;
    this.filters = {};
    this.logList = [];
    this.searchTerms = [];
  }
}
