import { Suspense, useCallback, useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import { NotificationContainer } from "src/hooks/useNotification";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

import views from "src/views";
import { Theme } from "src/assets/style/Theme";
import Global from "src/assets/style/Global";

import ConditionRoute from "src/routes/ConditionRoute";
import SuspenseLoader from "src/components/SuspenseLoader";

const App = observer(() => {
  const { authentication, applicationList, activeApplication, analysis } =
    useStores();

  const authNotInitialized = useCallback(() => {
    return authentication.state === "none";
  }, [authentication.state]);

  const initializeAuth = useCallback(async () => {
    await authentication.proof();
  }, [authentication]);

  const applicationNotInitialized = useCallback(() => {
    return authentication.state === "done" && applicationList.state === "none";
  }, [applicationList.state, authentication.state]);

  const initializeApplication = useCallback(async () => {
    await applicationList.readMany(authentication.user!);
  }, [applicationList, authentication.user]);

  const activeApplicationNotInitialized = useCallback(() => {
    return (
      applicationList.state === "done" && activeApplication.state === "none"
    );
  }, [activeApplication.state, applicationList.state]);

  const initializeActiveApplication = useCallback(async () => {
    const activeApplicationId = await activeApplication.getId();
    const application = await applicationList.get(activeApplicationId);
    await activeApplication.set(application);
  }, [activeApplication, applicationList]);

  useEffect(() => {
    if (authNotInitialized()) initializeAuth();
  }, [initializeAuth, authentication.state, authNotInitialized]);

  useEffect(() => {
    if (applicationNotInitialized()) initializeApplication();
  }, [
    applicationList,
    applicationList.state,
    authentication.state,
    authentication.user,
    initializeApplication,
    applicationNotInitialized,
  ]);

  useEffect(() => {
    if (activeApplicationNotInitialized()) initializeActiveApplication();
  }, [
    activeApplication,
    applicationList.state,
    activeApplication.state,
    initializeActiveApplication,
    activeApplicationNotInitialized,
  ]);

  const loggedIn = useMemo(() => {
    return authentication.state === "done" && authentication.isLogin;
  }, [authentication.state, authentication.isLogin]);

  const isCommonUser = useMemo(() => {
    return loggedIn && !authentication.user?.is_admin;
  }, [loggedIn, authentication.user]);

  const isAdmin = useMemo(() => {
    return loggedIn && !!authentication.user && authentication.user.is_admin;
  }, [loggedIn, authentication.user]);

  const ready = useMemo(() => {
    return (
      (authentication.state === "done" && applicationList.state === "done") ||
      authentication.state === "error"
    );
  }, [authentication.state, applicationList.state]);

  if (!ready) return null;

  return (
    <ThemeProvider theme={Theme.light}>
      <BrowserRouter>
        <Global />
        <Suspense fallback={<SuspenseLoader />}>
          <Routes>
            <Route path="/" element={<Navigate replace to="/auth/login" />} />
            <Route
              path="auth/*"
              element={
                <ConditionRoute
                  condition={!loggedIn}
                  redirectPath={
                    isAdmin ? "/admin/applications" : "/applications/my"
                  }
                >
                  <views.UserView />
                </ConditionRoute>
              }
            />
            <Route
              path="admin/*"
              element={
                <ConditionRoute
                  condition={isAdmin}
                  redirectPath={"/auth/login"}
                >
                  <views.AdminView />
                </ConditionRoute>
              }
            />
            <Route
              path="applications/*"
              element={
                <ConditionRoute
                  condition={isCommonUser}
                  redirectPath={"/auth/login"}
                >
                  <views.ApplicationsView />
                </ConditionRoute>
              }
            />
            <Route
              path="*"
              element={
                <ConditionRoute
                  condition={loggedIn}
                  redirectPath={"/auth/login"}
                >
                  <views.MainView />
                </ConditionRoute>
              }
            />
            <Route
              path="demo"
              element={
                <ConditionRoute
                  condition={loggedIn}
                  redirectPath={"/auth/login"}
                >
                  <views.DemoView />
                </ConditionRoute>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>

      <ToastArea>
        <NotificationContainer />
      </ToastArea>
    </ThemeProvider>
  );
});

const ToastArea = styled.section``;

export default App;
