import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import logoImage from "src/assets/images/42maru_hor.png";

const SuspenseLoader = () => {
  const [isprepare, setIsPrepare] = useState(false);

  useEffect(() => {
    return () => {
      setIsPrepare(true);
    };
  }, []);

  setTimeout(() => {
    setIsPrepare(true);
  }, 3000);

  return (
    <Wrapper>
      <div className="d-flex-ai-jc-center h-100">
        {isprepare && (
          <>
            <MaruSpinner className="mr-2" animation={"border"} />
            <img src={logoImage} alt={"logo"} height={40} />
          </>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff !important;
  height: 100vh !important;
`;

const MaruSpinner = styled(Spinner)`
  color: #202d86 !important;
`;

export default SuspenseLoader;
