import Base from "src/repositories";
import { DashboardIndexInfoRequest } from "src/types/index-status/schema";

class Dashboard extends Base {
  constructor() {
    super({});
  }

  async getNodes(application_id: string) {
    return this.axiosInstance.get(`/${application_id}/dashboard/node-status`);
  }

  async getIndices(application_id: string) {
    return this.axiosInstance.get(`/${application_id}/dashboard/index-status`);
  }

  async getSearchSpeed(
    application_id: string,
    payload: DashboardIndexInfoRequest
  ) {
    return this.axiosInstance.post(
      `/${application_id}/search-log/search-speed`,
      payload
    );
  }

  async getSearchCount(
    application_id: string,
    payload: DashboardIndexInfoRequest
  ) {
    return this.axiosInstance.post(
      `/${application_id}/search-log/search-count`,
      payload
    );
  }
}

export default new Dashboard();
