import React, { useEffect, useMemo, useState } from "react";
import { makeObservable, action, observable } from "mobx";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { AiOutlineCheck, AiOutlineWarning } from "react-icons/ai";
import { HiOutlineBan } from "react-icons/hi";

import { AlertInfo, AlertType } from "src/types/common";

class NotificationStore {
  data: { [id: string]: AlertInfo } = {};

  constructor() {
    makeObservable(this, {
      data: observable,
      success: action,
      warning: action,
      error: action,
      response_error: action,
      off: action,
    });
  }

  success(title: string, description?: string) {
    this.addAlertInfo("success", title, description);
  }

  warning(title: string, description?: string) {
    this.addAlertInfo("warning", title, description);
  }

  error(title: string, description?: string) {
    this.addAlertInfo("danger", title, description);
  }

  response_error(error: any, description?: string) {
    const title = error.response?.data?.error_message || "Error occurred.";
    this.addAlertInfo("danger", title, description);
  }

  off(id: string) {
    const { [id]: alertInfo, ...without } = this.data;
    this.data = without;
  }

  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  addAlertInfo(type: AlertType, title?: string, description?: string) {
    const id = uuid();
    this.data = {
      ...this.data,
      [id]: {
        id: id,
        type: type,
        title: title,
        description: description,
      },
    };
  }
}

const context = React.createContext(new NotificationStore());
const useNotification = () => React.useContext(context);

export const NotificationContainer = observer(() => {
  const alert = useNotification();

  return (
    <>
      {Object.keys(alert.data)
        .reverse()
        .map((id, index) => (
          <Notification key={id} id={id} index={index} />
        ))}
    </>
  );
});

export const Notification = observer(({ id, index }: NotificationProps) => {
  const alert = useNotification();
  const [on, setOn] = useState(false);

  useEffect(() => {
    setOn(true);

    setTimeout(() => {
      setOn(false);
      setTimeout(() => {
        alert.off(id);
      }, 305);
    }, 2000);
  }, [alert, id]);

  const alertInfo = useMemo(() => {
    return alert.data[id];
  }, [alert.data, id]);

  if (alertInfo) {
    return (
      <div className="flex justify-center">
        <NotificationStyle isOn={on} index={index} type={alert.data[id].type}>
          <div className="status-alert-content">
            {alertInfo.type === "success" && (
              <AiOutlineCheck className="text-green-400" />
            )}
            {alertInfo.type === "warning" && (
              <AiOutlineWarning className="text-yellow-400" />
            )}
            {alertInfo.type === "danger" && (
              <HiOutlineBan className="text-red-500" />
            )}

            <div className="ml-2 flex flex-col">
              <p>{alertInfo.title || ""}</p>
              <Description>{alertInfo.description}</Description>
            </div>
          </div>
        </NotificationStyle>
      </div>
    );
  } else {
    return null;
  }
});

type NotificationProps = {
  id: string;
  index?: number;
};

type NotificationStyleStyleProps = {
  isOn: boolean;
  type: string;
  index?: number;
};

const NotificationStyle = styled.div<NotificationStyleStyleProps>`
  position: fixed;
  -webkit-box-shadow: 0 14px 28px rgba(154, 161, 171, 0.25),
    0 10px 10px rgba(154, 161, 171, 0.22);
  box-shadow: 0 14px 28px rgba(154, 161, 171, 0.25),
    0 10px 10px rgba(154, 161, 171, 0.22);
  border-radius: 8px;
  transition: all 0.3s ease-out;
  min-width: 240px;
  z-index: 2000;

  background-color: ${(props) => props.theme.background.PANEL_BLACK};
  border: 1px solid black;
  color: ${(props) => props.theme.text.REVERSE};

  ${(props) =>
    props.index ? `bottom: ${40 + 65 * props.index}px;` : `bottom: 40px;`}

  div.status-alert-content {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 52px;
    padding: 0.75rem 1.5rem 0.75rem 1rem;

    p {
      max-width: 600px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    small {
      max-width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${(props) => (props.isOn ? `` : `bottom: -260px`)};
`;

const Description = styled.small`
  font-size: 12px;
  color: ${(props) => props.theme.text.REVERSE3};
`;

export default useNotification;
