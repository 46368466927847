import Base from "src/repositories";
import { TrendCreate, TrendUpdate, TrendDelete, TrendSearch } from "src/types/trend/schema";

class Trend extends Base {
  constructor() {
    super({});
  }

  async create(application_id: string, payload: TrendCreate) {
    return this.axiosInstance.post(`/${application_id}/trend`, payload);
  }

  async search(application_id: string, payload: TrendSearch) {
    return this.axiosInstance.post(`/${application_id}/trend/find`, payload);
  }

  async update(applicationId: string, trendId: string, payload: TrendUpdate) {
    return this.axiosInstance.put(`/${applicationId}/trend/${trendId}`, payload);
  }

  async delete(applicationId: string, payload: TrendDelete) {
    return this.axiosInstance.delete(`/${applicationId}/trend`, {
      data: payload,
    });
  }

  async upload(applicationId: string, payload: FormData) {
    return this.axiosInstance.post(`${applicationId}/trend/upload`, payload);
  }
}

export default new Trend();
