import { makeAutoObservable } from "mobx";
import repository from "src/repositories/BannedWord";
import { StoreState } from "src/types/common";
import { BannedWord } from "src/types/bannedword/model";
import {
  BannedWordSearchType,
  BannedWordSearchTargetField,
} from "src/types/common";
import {
  BannedWordCreate,
  BannedWordSearch,
  BannedWordUpdate,
  BannedWordDelete,
} from "src/types/bannedword/schema";

class BannedWordList {
  state: StoreState = "none";
  bannedWordList: BannedWord[] = [];
  bannedWord?: BannedWord;
  searchQuery: string = "";
  searchType: BannedWordSearchType = { en: "all", ko: "전체" };
  searchTarget: BannedWordSearchTargetField = { en: "all", ko: "전체" };
  page: number = 1;
  size: number = 20;
  deleteIdList: string[] = [];
  totalCount: number = 0;
  totalPage: number = 1;
  sort: string[] = ["-created_dt"];

  constructor() {
    makeAutoObservable(this);
  }

  setBannedWord(bannedWord?: BannedWord) {
    this.bannedWord = bannedWord;
  }

  setSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }

  setSearchType(searchType: BannedWordSearchType) {
    this.searchType = searchType;
  }

  setSearchTarget(searchTarget: BannedWordSearchTargetField) {
    this.searchTarget = searchTarget;
  }

  setPage(page: number) {
    this.page = page;
  }

  setDeleteIdList(list: string[]) {
    this.deleteIdList = list;
  }

  setSort(sort: string[]) {
    this.sort = sort;
  }

  async search(applicationId: string) {
    const payload: BannedWordSearch = {
      query: this.searchQuery,
      type: this.searchType.en,
      target: this.searchTarget.en,
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      const response = await repository.search(applicationId, payload);
      const tempList = response.data.bannedwords;

      for (let i = 0; i < tempList.length; i++) {
        const newType = {
          en: tempList[i].type,
          ko: tempList[i].type === "adult" ? "성인어" : "금기어",
        };

        tempList[i].type = newType;

        let newProcessingMethodKo = "";

        switch (tempList[i].processing_method) {
          case "include":
            newProcessingMethodKo = "포함하는 경우";
            break;

          case "match":
            newProcessingMethodKo = "일치하는 경우만";
            break;

          case "exclude":
            newProcessingMethodKo = "포함시 금칙어 제외";
            break;
        }

        const newProcessingMethod = {
          en: tempList[i].processing_method,
          ko: newProcessingMethodKo,
        };

        tempList[i].processing_method = newProcessingMethod;
      }

      this.bannedWordList = tempList;
      this.totalCount = response.data.total_count;
      this.totalPage = response.data.total_page;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async create(applicationId: string, payload: BannedWordCreate) {
    await repository.create(applicationId, payload);
  }

  async upload(applicationId: string, formData: FormData) {
    await repository.upload(applicationId, formData);
  }

  async update(
    applicationId: string,
    bannedwordId: string,
    payload: BannedWordUpdate
  ) {
    await repository.update(applicationId, bannedwordId, payload);
  }

  async delete(applicationId: string, payload: BannedWordDelete) {
    await repository.delete(applicationId, payload);
  }

  clear() {
    this.state = "none";
    this.bannedWordList = [];
    this.bannedWord = undefined;
    this.searchQuery = "";
    this.searchType = { en: "all", ko: "전체" };
    this.searchTarget = { en: "all", ko: "전체" };
    this.page = 1;
    this.deleteIdList = [];
    this.totalCount = 0;
    this.totalPage = 1;
    this.sort = ["-created_dt"];
  }

  setReady() {
    this.state = "none";
  }
}

export default BannedWordList;
