import Base from "src/repositories";
import {
  UserdictCreate,
  UserdictDelete,
  UserdictFind,
  UserdictUpdate,
} from "src/types/userdict/schema";

class Userdict extends Base {
  constructor() {
    super({});
  }

  async create(applicationId: string, payload: UserdictCreate) {
    return this.axiosInstance.post(`/${applicationId}/userdicts`, payload);
  }

  async update(
    applicationId: string,
    userdictId: string,
    payload: UserdictUpdate
  ) {
    return this.axiosInstance.put(
      `/${applicationId}/userdicts/${userdictId}`,
      payload
    );
  }

  async delete(applicationId: string, payload: UserdictDelete) {
    return this.axiosInstance.delete(`/${applicationId}/userdicts`, {
      data: payload,
    });
  }

  async read_many(applicationId: string, payload: UserdictFind) {
    return this.axiosInstance.post(`/${applicationId}/userdicts/find`, payload);
  }

  async upload(applicationId: string, payload: FormData) {
    return this.axiosInstance.post(
      `/${applicationId}/userdicts/upload`,
      payload
    );
  }
}

export default new Userdict();
