import { makeAutoObservable } from "mobx";
import repository from "src/repositories/Application";
import adminRepository from "src/repositories/Admin";
import { Sort, StoreState, ApplicationJob } from "src/types/common";
import { Application } from "src/types/application/model";
import {
  ApplicationCreate,
  ApplicationListResponse,
  ApplicationUpdate,
} from "src/types/application/schema";
import {LoginUser} from "../types/user/schema";

export default class ApplicationList {
  state: StoreState = "none";
  job?: ApplicationJob;
  applications: Application[] = [];
  myApplications: Application[] = [];
  sharingApplications: Application[] = [];
  sortType: Sort = {
    field: "created_dt",
    order: "desc",
  };

  constructor() {
    makeAutoObservable(this);
  }

  async get(applicationId: string | null) {
    if (!this.applications || !applicationId) return undefined;
    return this.applications.filter((a) => a.id === applicationId)[0];
  }

  async create(payload: ApplicationCreate) {
    try {
      const response = await repository.create(payload);
      return response.data;
    } catch (e) {
      throw e;
    }
  }


  async readMany(user: LoginUser, sortType?: Sort) {
    if (user.is_admin) {
      await this.readAll(sortType);
      return;
    }

    this.state = "pending";

    if (!sortType) this.sortType = { field: "created_dt", order: "desc" };
    else this.sortType = sortType;

    try {
      const response = await repository.readMany(user.email, this.sortType);
      const data: ApplicationListResponse = response.data;
      this.applications = data.applications;

      this.myApplications = this.applications.filter(
        (a) => a.creator.email === user.email
      );
      this.sharingApplications = this.applications.filter(
        (a) => a.creator.email !== user.email
      );
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async readAll(sortType?: Sort) {
    this.state = "pending";

    if (!sortType) this.sortType = { field: "created_dt", order: "desc" };
    else this.sortType = sortType;

    try {
      const response = await adminRepository.readApplicationMany(this.sortType);
      const data: ApplicationListResponse = response.data;
      this.applications = data.applications;
      this.myApplications = data.applications;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async update(applicationId: string, payload: ApplicationUpdate) {
    try {
      const response = await repository.update(applicationId, payload);
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(applicationId: string) {
    this.job = "delete";
    try {
      const response = await repository.delete(applicationId);
      this.job = undefined;
      return response.data;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async clear() {
    this.state = "none";
    this.job = undefined;
    this.applications = [];
    this.myApplications = [];
    this.sharingApplications = [];
    this.sortType = { field: "created_dt", order: "desc" };
  }
}
