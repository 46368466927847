import { makeAutoObservable } from "mobx";
// import repository from "src/repositories/Autocomplete";
import { SelectType, StoreState } from "src/types/common";
import { Autocomplete } from "src/types/autocomplete/model";
import { AutocompleteStatus } from "src/types/common";
// import {
//   AutocompleteCreate,
//   AutocompleteSearch,
//   AutocompleteUpdate,
//   AutocompleteDelete,
// } from "src/types/autocomplete/schema";

class AutocompleteList {
  state: StoreState = "none";
  autocompleteList: Autocomplete[] = [];
  autocomplete?: Autocomplete;
  searchQuery: string = "";
  status: AutocompleteStatus = { en: "all", ko: "전체" };
  page: number = 1;
  size: number = 20;
  deleteIdList: string[] = [];
  totalCount: number = 0;
  totalPage: number = 1;
  sort: string[] = ["-created_dt"];

  constructor() {
    makeAutoObservable(this);
  }

  setAutocomplete(autocomplete?: Autocomplete) {
    this.autocomplete = autocomplete;
  }

  setPage(page: number) {
    this.page = page;
  }

  setStatus(selected: AutocompleteStatus) {
    this.status = selected;
  }

  setSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }

  setDeleteIdList(list: string[]) {
    this.deleteIdList = list;
  }

  setSort(sort: string[]) {
    this.sort = sort;
  }

  clear() {
    this.state = "none";
    this.autocompleteList = [];
    this.autocomplete = undefined;
    this.searchQuery = "";
    this.status = { en: "all", ko: "전체" };
    this.page = 1;
    this.deleteIdList = [];
    this.totalCount = 0;
    this.totalPage = 1;
    this.sort = ["-created_dt"];
  }

  setReady() {
    this.state = "none";
  }
}

export default AutocompleteList;
