import Base from "src/repositories";
import {UserCreate, UserChangePassword, UpdateUser} from "src/types/user/schema";

class User extends Base {
  constructor() {
    super({ prefix: "/users" });
  }

  async read(email: string) {
    return this.axiosInstance.get(`/${email}`);
  }

  async join(payload: UserCreate) {
    return this.axiosInstance.post("", payload);
  }

  async update(email: string, payload: UpdateUser) {
    return this.axiosInstance.put(`/${email}`, payload);
  }

  async delete(email: string) {
    return this.axiosInstance.delete(`/${email}`);
  }

  async changePassword(payload: UserChangePassword) {
    return this.axiosInstance.put(`/password`, payload);
  }
}

export default new User();
