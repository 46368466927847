const COLORS = {
  BLACK: "#000000",
  MORTAR: "#505050",
  SHADY_LADY: "#9a9a9a",
  SILVER: "#bababa",
  ALTO: "#dedede",
  SEMI_WHITE: "#eaeaea",
  SOLITUDE: "#eeeff3",
  CONCRETE: "#f2f2f2",
  WHITE_SMOKE: "#F6F6F6",
  WHITE: "#ffffff",
  ERROR: "#f24343",
  WARNING: "#fdbe28",
  SUCCESS: "#47cdaf",
  CORNFLOWER_BLUE: "#608EF5",
  MELROSE: "#a3a3ff",
  LAVENDER: "#d5d7f8",
  GHOST: "#c2c4d6",
  COMET: "#5b5e86",
  RHINO: "#383a5c",
  MOON_RAKER_40: "rgba(213, 215, 248, 0.4)",
  BILOBA_FLOWER_20: "rgba(168, 173, 236, 0.2)",
};

export const Theme = {
  light: {
    background: {
      NAVBAR: COLORS.RHINO,
      SIDEBAR: COLORS.COMET,
      SIDEBAR_HOVER: COLORS.BILOBA_FLOWER_20,
      DESCRIPTION: COLORS.SEMI_WHITE,
      PANEL: COLORS.WHITE,
      PANEL2: COLORS.WHITE_SMOKE,
      PANEL3: COLORS.SOLITUDE,
      PANEL_BLACK: COLORS.BLACK,
      PANEL_DARK: COLORS.MORTAR,
      PANEL_HOVER: COLORS.CONCRETE,
      SWITCH_OFF: COLORS.ALTO,
      SWITCH_ON: COLORS.GHOST,
      SUCCESS: COLORS.SUCCESS,
      INFO: COLORS.CORNFLOWER_BLUE,
      ERROR: COLORS.ERROR,
    },
    text: {
      MAIN: COLORS.COMET,
      SECOND: COLORS.MORTAR,
      REVERSE: COLORS.WHITE,
      REVERSE2: COLORS.SOLITUDE,
      REVERSE3: COLORS.ALTO,
      SUCCESS: COLORS.SUCCESS,
      WARNING: COLORS.WARNING,
      ERROR: COLORS.ERROR,
      PLACEHOLDER: COLORS.SHADY_LADY,
      BLACK: COLORS.BLACK,
      DIM: COLORS.GHOST,
      BLUE: COLORS.CORNFLOWER_BLUE,
      SILVER: COLORS.SILVER,
      SIDEBAR: COLORS.MOON_RAKER_40,
      SIDEBAR_ACTIVE: COLORS.MELROSE,
      SIDEBAR_ACTIVE2: COLORS.LAVENDER,
    },
    button: {
      MAIN: COLORS.COMET,
      SUCCESS: COLORS.SUCCESS,
      WARNING: COLORS.WARNING,
      ERROR: COLORS.ERROR,
      CANCEL: COLORS.SEMI_WHITE,
      NOT_ALLOWED: COLORS.SILVER,
      GREY: COLORS.SOLITUDE,
      SWITCH_OFF: COLORS.SHADY_LADY,
      SWITCH_ON: COLORS.COMET,
    },
    border: {
      MAIN: COLORS.COMET,
      SECOND: COLORS.SEMI_WHITE,
      THIRD: COLORS.SILVER,
      ERROR: COLORS.ERROR,
    },
    COMET_COLOR: COLORS.COMET,
  },
  dark: {},
};
