import { makeAutoObservable } from "mobx";
import repository from "src/repositories/Authentication";
import { StoreState } from "src/types/common";
import {LoginUser, UserLogin} from "src/types/user/schema";

export default class Authentication {
  user?: LoginUser;
  state: StoreState = "none";
  isLogin: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async proof() {
    this.state = "pending";
    try {
      const res = await repository.proofUser();
      this.user = {
        email: res.data.email,
        name: res.data.username,
        is_admin: res.data.is_admin,
        team: res.data.team,
        nickname: res.data.nickname
      };
      this.isLogin = true;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      console.error(e);
    }
  }

  async logout() {
    try {
      await repository.logout();
      await this.clear();
    } catch (e) {
      throw e;
    }
  }

  async login(payload: UserLogin) {
    this.state = "pending";
    try {
      await repository.login(payload);
      this.isLogin = true;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async loginKeeper() {
    await repository.loginKeeper();
  }

  async clear() {
    this.state = "none";
    this.user = undefined;
    this.isLogin = false;
  }
}
