import Base from "src/repositories";
import { DSLSearchCriteria, SearchCriteria } from "src/types/search/schema";

class Search extends Base {
  constructor() {
    super({});
  }

  async read(applicationId: string, indexName: string, payload: SearchCriteria) {
    return this.axiosInstance.post(
      `/${applicationId}/${indexName}/search`,
      payload
    );
  }

  async readDSL(
    applicationId: string,
    indexName: string,
    dslID: string,
    payload: DSLSearchCriteria
  ) {
    return this.axiosInstance.post(
      `/${applicationId}/${indexName}/dsls/${dslID}/search`,
      payload
    );
  }
}

export default new Search();
