import {makeAutoObservable} from "mobx";
import repository from "src/repositories/Userdict";
import {StoreState, UserDictSearchTarget, UserDictSearchType,} from "src/types/common";
import {Userdict} from "src/types/userdict/model";
import {UserdictCreate, UserdictDelete, UserdictFind, UserdictUpdate,} from "src/types/userdict/schema";

class UserdictList {
  state: StoreState = "none";
  userdictList: Userdict[] = [];
  userdict?: Userdict;
  searchQuery: string = "";
  searchType: UserDictSearchType = { en: "all", ko: "전체" };
  searchTarget: UserDictSearchTarget = { en: "all", ko: "전체" };
  page: number = 1;
  size: number = 20;
  deleteIdList: string[] = [];
  totalCount: number = 0;
  totalPage: number = 1;
  sort: string[] = ["-created_dt"];

  constructor() {
    makeAutoObservable(this);
  }

  setUserdict(userdict?: Userdict) {
    this.userdict = userdict;
  }

  setSearchQuery(searchQuery: string) {
    this.searchQuery = searchQuery;
  }

  setSearchType(searchType: UserDictSearchType) {
    this.searchType = searchType;
  }

  setSearchTarget(searchTarget: UserDictSearchTarget) {
    this.searchTarget = searchTarget;
  }

  setPage(page: number) {
    this.page = page;
  }

  setDeleteIdList(list: string[]) {
    this.deleteIdList = list;
  }

  setSort(sort: string[]) {
    this.sort = sort;
  }

  async search(applicationId: string) {
    const payload: UserdictFind = {
      query: this.searchQuery,
      type: this.searchType.en,
      target: this.searchTarget.en,
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      const response = await repository.read_many(applicationId, payload);
      const tempList = response.data.userdicts;

      for (let i = 0; i < tempList.length; i++) {
        const tempType = tempList[i].type;
        let tempTypeKo = "";

        switch (tempType) {
          case "noun":
            tempTypeKo = "일반명사";
            break;

          case "proper":
            tempTypeKo = "고유명사";
            break;

          case "compound":
            tempTypeKo = "복합명사";
            break;

          case "etc":
            tempTypeKo = "기타";
            break;
        }

        tempList[i].type = {
          en: tempType,
          ko: tempTypeKo,
        };
      }

      this.userdictList = tempList;
      this.totalCount = response.data.total;
      this.totalPage = response.data.total_page;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async create(applicationId: string, payload: UserdictCreate) {
    await repository.create(applicationId, payload);
  }

  async upload(applicationId: string, formData: FormData) {
    await repository.upload(applicationId, formData);
  }

  async update(
    applicationId: string,
    userdict_id: string,
    payload: UserdictUpdate
  ) {
    await repository.update(applicationId, userdict_id, payload);
  }

  async delete(applicationId: string, payload: UserdictDelete) {
    await repository.delete(applicationId, payload);
  }

  clear() {
    this.state = "none";
    this.userdictList = [];
    this.userdict = undefined;
    this.searchQuery = "";
    this.searchType = { en: "all", ko: "전체" };
    this.searchTarget = { en: "all", ko: "전체" };
    this.page = 1;
    this.deleteIdList = [];
    this.totalCount = 0;
    this.totalPage = 1;
    this.sort = ["-created_dt"];
  }

  setReady() {
    this.state = "none";
  }
}

export default UserdictList;
