import { makeAutoObservable } from "mobx";
import bytes from "bytes";

import repository from "src/repositories/Dashboard";
import { StoreState } from "src/types/common";
import { IndexStatus } from "src/types/index-status/model";

export default class IndexDashboard {
  state: StoreState = "none";
  indexStatusList: IndexStatus[] = [];
  initialIndexList: IndexStatus[] = [];
  size: number = 5;
  page: number = 1;
  searched: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get totalStatus() {
    for (const index of this.indexStatusList) {
      if (index.health === "yellow") return "yellow";
      else if (index.health === "red") return "red";
    }

    return "green";
  }

  get totalPri() {
    let total = 0;

    this.indexStatusList.forEach((index) => {
      total += index.pri;
    });

    return total;
  }

  get totalRep() {
    let total = 0;

    this.indexStatusList.forEach((index) => {
      total += index.rep;
    });

    return total;
  }

  get totalDocuments() {
    let total = 0;

    this.indexStatusList.forEach((index) => {
      total += index.docs_count;
    });

    return total.toLocaleString();
  }

  get totalDataSize() {
    let total = 0;

    this.indexStatusList.forEach((index) => {
      total += index.store_size;
    });

    return bytes(total, { unitSeparator: " " });
  }

  get lastPage() {
    return Math.ceil(this.indexStatusList.length / this.size);
  }

  setSearched(value: boolean) {
    this.searched = value;
  }

  setIndexStatusList(list: IndexStatus[]) {
    this.indexStatusList = list;
  }

  setSize(size: number) {
    this.size = size;
  }

  setPage(page: number) {
    this.page = page;
  }

  async getList(applicationId: string) {
    this.state = "pending";

    try {
      const response = await repository.getIndices(applicationId);

      this.indexStatusList = response.data.response;
      this.initialIndexList = response.data.response;
      this.state = "done";
    } catch (e) {
      this.state = "error";
    }
  }

  resetSearch() {
    this.indexStatusList = this.initialIndexList;
  }

  clear() {
    this.state = "none";
    this.indexStatusList = [];
  }
}
