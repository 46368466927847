import React from "react";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

type ConditionRouteProps = {
  condition: boolean;
  redirectPath: string;
};

const ConditionRoute: React.FC<ConditionRouteProps> = observer(
  ({ condition, redirectPath, children }) => {
    if (condition) {
      return <>{children}</>;
    } else {
      return <Navigate replace to={redirectPath} />;
    }
  }
);

export default ConditionRoute;
