import Base from "src/repositories";
import { GetPopularTermList, UpdatePopularTermList } from "src/types/popular-term/schema";

class PopularTerm extends Base {
  constructor() {
    super({});
  }

  async getList(applicationId: string, payload: GetPopularTermList) {
    return this.axiosInstance.post(`${applicationId}/search-log/popular-terms`, payload);
  }

  async updateList(applicationId: string, payload: UpdatePopularTermList) {
    return this.axiosInstance.put(`${applicationId}/search-log/popular-terms`, payload);
  }
}

export default new PopularTerm();
