import Base from "src/repositories";
import { PythonDSLCreate, PythonDSLUpdate } from "src/types/python-dsl/schema";

class PythonDSL extends Base {
  constructor() {
    super({});
  }

  async create(
    applicationId: string,
    indexName: string,
    payload: PythonDSLCreate
  ) {
    return this.axiosInstance.post(
      `/${applicationId}/${indexName}/python-dsl`,
      payload
    );
  }

  async readMany(applicationId: string, indexName: string) {
    return this.axiosInstance.get(`/${applicationId}/${indexName}/python-dsl`);
  }

  async update(
    applicationId: string,
    indexName: string,
    dslId: string,
    payload: PythonDSLUpdate
  ) {
    return this.axiosInstance.put(
      `/${applicationId}/${indexName}/python-dsl/${dslId}`,
      payload
    );
  }

  async delete(applicationId: string, indexName: string, dslId: string) {
    return this.axiosInstance.delete(
      `/${applicationId}/${indexName}/python-dsl/${dslId}`
    );
  }
}

export default new PythonDSL();
