import Base from "src/repositories";
import {
  SynonymCreate,
  SynonymUpdate,
  SynonymDelete,
  SynonymFind,
} from "src/types/synonym/schema";

class Synonym extends Base {
  constructor() {
    super({});
  }

  async create(application_id: string, payload: SynonymCreate) {
    return this.axiosInstance.post(`/${application_id}/synonyms`, payload);
  }

  async search(application_id: string, payload: SynonymFind) {
    return this.axiosInstance.post(`/${application_id}/synonyms/find`, payload);
  }

  async update(
    application_id: string,
    synonym_id: string,
    payload: SynonymUpdate
  ) {
    return this.axiosInstance.put(
      `/${application_id}/synonyms/${synonym_id}`,
      payload
    );
  }

  async delete(application_id: string, payload: SynonymDelete) {
    return this.axiosInstance.delete(`/${application_id}/synonyms`, {
      data: payload,
    });
  }

  async getDomains(application_id: string) {
    return this.axiosInstance.get(`/${application_id}/synonyms/domains`);
  }

  async upload(application_id: string, payload: FormData) {
    return this.axiosInstance.post(
      `${application_id}/synonyms/upload`,
      payload
    );
  }
}

export default new Synonym();
