import Base from "src/repositories";

class Analysis extends Base {
  constructor() {
    super({});
  }

  async readMany() {
    return this.axiosInstance.get(`/analyzers`);
  }

  async readManyByIndex(appId: string, indexName: string) {
    return this.axiosInstance.get(`/${appId}/${indexName}/analyzers`);
  }

  async analyze(
    applicationId: string,
    indexName: string,
    text: string,
    analyzers: string
  ) {
    return this.axiosInstance.get(`/${applicationId}/${indexName}/analyze`, {
      params: {
        text: text,
        analyzers: analyzers,
      },
    });
  }

  async tokenize(text: string, tokenizers: string) {
    return this.axiosInstance.get("/analyze", {
      params: {
        text: text,
        tokenizers: tokenizers,
      },
    });
  }
}

export default new Analysis();
