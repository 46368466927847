import { makeAutoObservable } from "mobx";
import { StoreState } from "src/types/common";

class CsvFile {
  state: StoreState = "none";
  csvFile?: File;

  constructor() {
    makeAutoObservable(this);
  }

  setCsvFile(file?: File) {
    if (file) {
      this.csvFile = file;
      this.state = "done";
    } else {
      this.state = "none";
    }
  }

  clear() {
    this.state = "none";
    this.csvFile = undefined;
  }
}

export default CsvFile;
