import Base from "src/repositories";

class Information extends Base {
  constructor() {
    super({});
  }

  async readMany(applicationId: string) {
    return this.axiosInstance.get(`/${applicationId}/info`);
  }
}

export default new Information();
