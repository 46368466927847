import Base from "src/repositories";
import {
  ApplicationCreate,
  ApplicationUpdate,
} from "src/types/application/schema";
import { Sort } from "src/types/common";

class Application extends Base {
  constructor() {
    super({ prefix: "/applications" });
  }

  async create(payload: ApplicationCreate) {
    return this.axiosInstance.post(``, payload);
  }

  async read(applicationId: string) {
    return this.axiosInstance.get(`/${applicationId}`);
  }

  async readMany(email: string, sort: Sort) {
    return this.axiosInstance.get("", {
      params: {
        email: email,
        field: sort.field,
        order: sort.order,
      },
    });
  }

  async update(applicationId: string, payload: ApplicationUpdate) {
    return this.axiosInstance.put(`/${applicationId}`, payload);
  }

  async delete(applicationId: string) {
    return this.axiosInstance.delete(`/${applicationId}`);
  }
}

export default new Application();
