import Base from "src/repositories";
import { DemoUpdate } from "src/types/demo/schema";

class Demo extends Base {
  constructor() {
    super({});
  }

  async read(applicationId: string, indexName: string) {
    return this.axiosInstance.get(`/${applicationId}/${indexName}/demos`);
  }

  async update(applicationId: string, indexName: string, payload: DemoUpdate) {
    return this.axiosInstance.put(
      `/${applicationId}/${indexName}/demos`,
      payload
    );
  }
}

export default new Demo();
