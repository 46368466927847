import Base from "src/repositories";
import {
  SingleDocumentCreate,
  SingleDocumentUpdate,
} from "src/types/document/schema";

class Document extends Base {
  constructor() {
    super({});
  }

  async read(applicationId: string, indexName: string, documentId: string) {
    return this.axiosInstance.get(
      `/${applicationId}/${indexName}/documents/${documentId}`
    );
  }

  async create(
    applicationId: string,
    indexName: string,
    payload: SingleDocumentCreate
  ) {
    return this.axiosInstance.post(
      `/${applicationId}/${indexName}/documents`,
      payload
    );
  }

  async delete(applicationId: string, indexName: string, documentId: string) {
    return this.axiosInstance.delete(
      `/${applicationId}/${indexName}/documents/${documentId}`
    );
  }

  async update(
    applicationId: string,
    indexName: string,
    documentId: string,
    payload: SingleDocumentUpdate
  ) {
    return this.axiosInstance.put(
      `/${applicationId}/${indexName}/documents/${documentId}`,
      payload
    );
  }

  async reindex(applicationId: string, indexName: string) {
    return this.axiosInstance.post(
      `/${applicationId}/${indexName}/documents/_reindex`
    );
  }

  async upload(applicationId: string, indexName: string, payload: FormData) {
    return this.axiosInstance.post(
      `/${applicationId}/${indexName}/documents/upload`,
      payload
    );
  }
}

export default new Document();
