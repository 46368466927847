import Base from "src/repositories";
import {PolicyType, Sort} from "src/types/common";
import {UpdateUser} from "src/types/user/schema";
import {PolicyUpdate} from "src/types/policy/schema";

class Admin extends Base {
  constructor() {
    super({ prefix: "/admin" });
  }

  async readApplicationMany(sort: Sort) {
    return this.axiosInstance.get("/application", {
      params: {
        field: sort.field,
        order: sort.order,
      },
    });
  }

  async readUserMany() {
    return this.axiosInstance.get("/user");
  }

  async updateUser(email: string, payload: UpdateUser) {
    return this.axiosInstance.put(`/user/${email}`, payload);
  }

  async deleteUser(email: string) {
    return this.axiosInstance.delete(`/user/${email}`);
  }

  async readPolicy(type: PolicyType) {
    return this.axiosInstance.get(`/policy/${type}`);
  }

  async addUser(type: PolicyType, email: string) {
    return this.axiosInstance.post(`/policy/${type}/${email}`);
  }

  async popUser(type: PolicyType, email: string) {
    return this.axiosInstance.delete(`/policy/${type}/${email}`,);
  }

  async updatePolicy(type: PolicyType, payload: PolicyUpdate) {
    return this.axiosInstance.put(`/policy/${type}`, payload);
  }

}

export default new Admin();
