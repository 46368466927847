import { makeAutoObservable } from "mobx";
import _ from "lodash";
import { StoreState } from "src/types/common";
import { Application } from "src/types/application/model";

export default class ActiveApplication {
  storageId: string = "activeAppId";
  application?: Application;
  state: StoreState = "none";

  constructor() {
    makeAutoObservable(this);
  }

  async getId() {
    return localStorage.getItem(this.storageId);
  }

  async set(application?: Application) {
    this.state = "pending";
    if (application) {
      localStorage.setItem(this.storageId, application.id);
      this.application = _.clone(application);
    } else {
      localStorage.removeItem(this.storageId);
      this.application = undefined;
    }
    this.state = "done";
  }

  async clear() {
    this.state = "none";
    this.application = undefined;
  }
}
