import { makeAutoObservable } from "mobx";
import repository from "src/repositories/Admin";
import {PolicyType, StoreState} from "src/types/common";
import { Policy as Model } from "src/types/policy/model";

export default class Policy {
  state: StoreState = "none";
  type?: PolicyType;
  policy?: Model;

  constructor() {
    makeAutoObservable(this);
  }

  async read(type?: PolicyType) {
    if (!this.type && !type) return;
    this.state = "pending";
    try {
      if (!this.type) this.type = type;
      const response = await repository.readPolicy(this.type!);
      this.policy = response.data;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
    this.state = "done";
  }

  async addUser(email: string) {
    const response = await repository.addUser(this.type!, email);
    return response.data;
  }

  async popUser(email: string) {
    const response = await repository.popUser(this.type!, email);
    return response.data;
  }

  async updatePolicy() {
    const response = await repository.updatePolicy(
      this.type!,
      {activate: !this.policy!.activate}
    )
    this.policy!.activate = !this.policy!.activate;
    return response.data;
  }

  async clear() {
    this.policy = undefined;
    this.type = undefined;
    this.state = "none";
  }
}
