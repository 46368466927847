import Base from "src/repositories";
import { TokenCreate, TokenUpdate } from "src/types/token/schema";

class Token extends Base {
  constructor() {
    super({ prefix: "/tokens" });
  }

  async create(payload: TokenCreate) {
    return this.axiosInstance.post(``, payload);
  }

  async readMany(applicationId: string) {
    return this.axiosInstance.get(`/${applicationId}`);
  }

  async update(tokenId: string, payload: TokenUpdate) {
    return this.axiosInstance.put(`/${tokenId}`, payload);
  }

  async delete(tokenId: string) {
    return this.axiosInstance.delete(tokenId);
  }
}

export default new Token();
