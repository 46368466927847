import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

import "src/assets/style/style.scss";
import "react-datepicker/dist/react-datepicker.css";
import "katex/dist/katex.min.css";

const Global = createGlobalStyle`
  ${reset}
  
  body {
    background: #f5f5f5;
    min-width: 1440px;
    overflow-x: auto;
    em {
      background: linear-gradient(to top, yellow 50%, transparent 50%);
    }
  }
  `;

export default Global;

export const layoutSizes = {
  NAVBAR_HEIGHT: "55px",
  SIDEBAR_WIDTH: "240px",
  INSIDE_SIDEBAR_WIDTH: "388px",
};
