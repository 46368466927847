import { makeAutoObservable } from "mobx";
import repository from "src/repositories/Indices";
import informationRepository from "src/repositories/Information";
import { StoreState, IndexJob } from "src/types/common";
import { Index } from "src/types/index/model";
import {
  IndexCopy,
  IndexCreate,
  IndexDisplayUpdate,
  IndexSearchSettingUpdate,
} from "src/types/index/schema";
import { ApplicationInfo } from "src/types/information/schema";

export default class IndexList {
  state: StoreState = "none";
  job?: IndexJob;
  indices: Index[] = [];
  information?: ApplicationInfo;

  constructor() {
    makeAutoObservable(this);
  }

  async get(indexId: string | null) {
    if (!this.indices) return undefined;
    if (!indexId) return this.indices[0];
    const index = this.indices.filter((i) => i._id === indexId);
    if (!index.length) return this.indices[0];
    return index[0];
  }

  getInfo(indexId: string) {
    return this.information!.indices.filter((info) => {
      return info.id === indexId;
    })[0];
  }

  async readMany(applicationId: string) {
    this.state = "pending";
    try {
      const response = await repository.readMany(applicationId);
      this.indices = response.data.indices ? response.data.indices : [];
      const infoResponse = await informationRepository.readMany(applicationId);
      this.information = infoResponse.data;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async justReadMany(applicationId: string) {
    try {
      const response = await repository.readMany(applicationId);
      this.indices = response.data.indices ? response.data.indices : [];
      const infoResponse = await informationRepository.readMany(applicationId);
      this.information = infoResponse.data;
    } catch (e) {
      throw e;
    }
  }

  async create(applicationId: string, payload: IndexCreate) {
    this.job = "create";
    try {
      const response = await repository.create(applicationId, payload);
      const data: Index = response.data;
      this.job = undefined;
      return data;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async copy(applicationId: string, indexName: string, payload: IndexCopy) {
    this.job = "copy";
    try {
      const response = await repository.copy(applicationId, indexName, payload);
      this.job = undefined;
      return response.data;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async delete(applicationId: string, indexName: string) {
    this.job = "delete";
    try {
      await repository.delete(applicationId, indexName);
      this.indices = this.indices.filter(i => i.name !== indexName);
      this.job = undefined;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  async updateIndexDisplay(
    applicationId: string,
    indexName: string,
    payload: IndexDisplayUpdate
  ) {
    try {
      const response = await repository.updateIndexDisplay(
        applicationId,
        indexName,
        payload
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async updateIndexSearchSetting(
    applicationId: string,
    indexName: string,
    payload: IndexSearchSettingUpdate
  ) {
    try {
      const response = await repository.updateIndexSearchSettings(
        applicationId,
        indexName,
        payload
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async clear() {
    this.state = "none";
    this.job = undefined;
    this.indices = [];
    this.information = undefined;
  }
}
