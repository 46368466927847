import { makeAutoObservable } from "mobx";
import _ from "lodash";
import indexRepository from "src/repositories/Indices";
import { Dict, Order, StoreState, IndexJob } from "src/types/common";
import { Schema } from "src/types/index/schema";

export default class Mappings {
  state: StoreState = "none";
  job?: IndexJob;
  mapping?: object;
  schemas: Schema[] = [];
  order: Order = "asc";

  constructor() {
    makeAutoObservable(this);
  }

  async changedSchemas() {
    const properties: Schema[] = [];
    const originSchemas = this.parseMappingToSchema(this.mapping!);
    this.schemas.forEach((schema) => {
      const originSchema = originSchemas.filter((o) => _.isEqual(o, schema));
      if (originSchema.length === 0) properties.push(schema);
    });
    return properties;
  }

  async save(applicationId: string, indexName: string) {
    try {
      const properties = await this.changedSchemas();
      await indexRepository.updateMappings(applicationId, indexName, {properties: properties})
    } catch (e) {
      throw e;
    }
  }

  async saveWithReindex(applicationId: string, indexName: string) {
    this.job = "reindex";
    try {
      const properties = await this.changedSchemas();
      await indexRepository.updateMappings(applicationId, indexName, {properties: properties, with_reindex: true})
      this.job = undefined;
    } catch (e) {
      this.job = undefined;
      throw e;
    }
  }

  sortingWay(a: string, b: string) {
    if (this.order === "asc") {
      return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    } else {
      return a.toLowerCase() > b.toLowerCase() ? -1 : 1;
    }
  }

  getFieldType(field: string) {
    const schema = this.schemas.filter(s => s.field === field)
    if (schema.length) return schema[0].properties!.type
    return undefined;
  }

  async setAnalyzer(index: number, analyzer: string) {
    const schema = this.schemas[index];
    schema.properties!.analyzer = analyzer;
    this.schemas[index] = schema;
  }

  async sort(order?: Order) {
    if (order) this.order = order;
    this.schemas.sort((schemaA, schemaB) => {
      return this.sortingWay(
        schemaA.parent ? schemaA.parent : schemaA.field,
        schemaB.parent ? schemaB.parent : schemaB.field
      );
    });
  }

  async addSchema(schema: Schema) {
    this.schemas = [...this.schemas, schema];
    await this.sort(this.order);
  }

  async deleteSchema(index: number) {
    const schema = this.schemas[index];
    schema.is_deleted = true;
    this.schemas[index] = schema;
  }

  async resetSchemas() {
    this.state = "pending";
    this.schemas = this.parseMappingToSchema(this.mapping!);
    await this.sort();
    this.state = "done";
  }

  parseCondition(field: string) {
    return field !== "keyword";
  }

  parseMappingToSchema(data: object, parent?: string) {
    const result: Schema[] = [];
    Object.entries(data).forEach(([field, mapping]) => {
      const properties: Dict = {};
      Object.entries(mapping).forEach(([key, value]) => {
        if (key === "fields" && typeof value === "object") {
          result.push(...this.parseMappingToSchema(value!, field));
        } else {
          properties[key] = value;
        }
      });
      if (this.parseCondition(field))
        result.push({
          field: field,
          is_deleted: false,
          parent: parent,
          properties: {
            type: properties.type? properties.type: "object",
            analyzer: properties.analyzer,
          },
        });
    });
    return result;
  }

  async read(applicationId: string, indexName: string) {
    this.state = "pending";
    try {
      const response = await indexRepository.readMappings(
        applicationId,
        indexName
      );
      this.mapping = response.data.properties.properties;
      this.schemas = this.parseMappingToSchema(this.mapping!);
      await this.sort(this.order);
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async clear() {
    this.state = "none";
    this.job = undefined;
    this.mapping = undefined;
    this.schemas = [];
    this.order = "asc";
  }
}
