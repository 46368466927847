import { makeAutoObservable } from "mobx";
import _ from "lodash";
import { Dict, StoreState } from "src/types/common";
import { Schema } from "src/types/index/schema";

export default class ActiveDocument {
  id?: string;
  document?: Dict;
  originDocument?: Dict;
  activateObjectField?: string;
  form?: Dict;
  state: StoreState = "none";
  formState: StoreState = "none";

  constructor() {
    makeAutoObservable(this);
  }

  async set(documentId: string, document?: Dict) {
    this.state = "pending";
    if (document) {
      this.id = documentId;
      this.document = _.clone(document);
      this.originDocument = _.clone(document);
    } else {
      this.document = undefined;
    }
    this.state = "done";
  }

  async setForm(schemas?: Schema[]) {
    this.formState = "pending";
    if (schemas) {
      const result: Dict = {};
      schemas.forEach((s) => (result[s.field] = undefined));
      this.form = result;
    } else {
      this.form = undefined;
    }
    this.formState = "done";
  }

  async getForm() {
    if (!this.form) return undefined;
    const result = Object.entries(this.form!).filter(([key, value]) => value);
    if (!result) return undefined;
    return result.reduce((accum: Dict, [k, v]) => {
      accum[k] = v;
      return accum;
    }, {});
  }

  getValue(key: string) {
    return this.document![key];
  }

  setValue(key: string, value: any) {
    this.document = { ...this.document, [key]: value };
  }

  getFormValue(key: string) {
    return this.form![key];
  }

  async setFormValue(key: string, value: any) {
    this.form![key] = value;
  }

  async setActivateObjectField(field: string) {
    this.activateObjectField = field;
  }

  async reset() {
    this.document = _.clone(document);
  }

  async clearActivateObjectField() {
    this.activateObjectField = undefined;
  }

  async isChanged() {
    return !_.isEqual(this.document, this.originDocument);
  }

  async clearForm() {
    this.form = undefined;
    this.formState = "none";
  }

  async clear() {
    this.state = "none";
    this.formState = "none";
    this.id = undefined;
    this.document = undefined;
    this.originDocument = undefined;
    this.activateObjectField = undefined;
    this.form = undefined;
  }
}
