import React from "react";

const views = {
  UserView: React.lazy(() => import("src/views/user")),
  AdminView: React.lazy(() => import("src/views/admin")),
  ApplicationsView: React.lazy(() => import("src/views/applications")),
  MainView: React.lazy(() => import("src/views/MainView")),
  DemoView: React.lazy(() => import("src/views/demo/DemoView")),
};

export default views;
