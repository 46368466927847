import Base from "src/repositories";
import { StopWordCreate } from "src/types/stopword/schema";

class StopWord extends Base {
  constructor() {
    super({});
  }

  async create(
    applicationId: string,
    indexName: string,
    payload: StopWordCreate
  ) {
    return this.axiosInstance.post(
      `/${applicationId}/${indexName}/stopwords`,
      payload
    );
  }

  async readMany(applicationId: string, indexName: string) {
    return this.axiosInstance.get(`/${applicationId}/${indexName}/stopwords`);
  }

  async delete(applicationId: string, indexName: string, stopWordId: string) {
    return this.axiosInstance.delete(
      `/${applicationId}/${indexName}/stopwords/${stopWordId}`
    );
  }
}

export default new StopWord();
