import { makeAutoObservable } from "mobx";

export default class NewDashboard {
  selectedIndex?: string;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIndex(indexId: string) {
    this.selectedIndex = indexId;
  }

  clear() {
    this.selectedIndex = undefined;
  }
}
