import Base from "src/repositories";

class AuditLogList extends Base {
  constructor() {
    super({});
  }

  async readMany(applicationId: string, payload: any) {
    return this.axiosInstance.post(`/${applicationId}/audit-log`, payload);
  }

  async getResources(applicationId: string) {
    return this.axiosInstance.get(`/${applicationId}/audit-log/resources`);
  }
}

export default new AuditLogList();
